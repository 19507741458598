<div
  class="assessment-content"
  [class.assessment-content_no-left-bar]="false"
  [class.assessment-content_no-right-bar]="true">
  <aside class="assessment-content_leftbar">
    <div class="assessment-content_leftbar_search-title">Search</div>

    <div class="assessment-content_leftbar_search">
      <mat-form-field class="assessment-content_leftbar_search-field">
        <div class="d-flex">
          <mat-icon class="me-2" matTextPrefix svgIcon="search" />
          <input matInput placeholder="Search Assessments" (input)="onSearchInputChange($event)" />
        </div>
      </mat-form-field>

      <div class="phar-icon-button filter ms-auto" pharTooltip="Filters" [matMenuTriggerFor]="filters">
        <mat-icon svgIcon="filter" />
      </div>
      <mat-menu #filters="matMenu" [overlapTrigger]="false">
        <button mat-menu-item [disabled]="filterByType()" (click)="addFilterByType()">
          <span>Form type</span>
        </button>
      </mat-menu>
    </div>

    @if (filterByType()) {
      <div class="assessment-content_leftbar_search mb-3">
        <phar-filter-item [filter]="filterByType()" (filterUpdate)="handleFilterUpdate($event)" />
      </div>
    }
    <div class="assessment-content_leftbar_forms-section">
      <h6 class="cursor-pointer" (click)="toggleLastReleasedSection()">
        Last Released
        <ng-container *ngTemplateOutlet="openCloseTmp; context: { state: lastReleasedSectionState }" />
      </h6>
      <div [@toggleSidebarSection]="lastReleasedSectionState">
        @if (filteredForms$ | async | slice: 0 : lastReleasedFormsCount; as lastReleased) {
          <ng-container *ngTemplateOutlet="formTemplate; context: { forms: lastReleased }" />
        }
      </div>
      @if (filteredForms$ | async | slice: lastReleasedFormsCount; as releasedForms) {
        @if (releasedForms.length) {
          <h6 class="cursor-pointer" (click)="toggleLibrarySection()">
            Library
            <ng-container *ngTemplateOutlet="openCloseTmp; context: { state: librarySectionState }" />
          </h6>
          <div [@toggleSidebarSection]="librarySectionState">
            <ng-container *ngTemplateOutlet="formTemplate; context: { forms: releasedForms }" />
          </div>
        }
      }
    </div>
  </aside>

  <ng-template #formTemplate let-forms="forms">
    <mat-nav-list
      cdkDropList
      [cdkDropListSortingDisabled]="true"
      [cdkDropListConnectedTo]="connectedTo"
      [cdkDropListDisabled]="isLoading()"
      [cdkDropListData]="forms"
      [id]="listName">
      @for (form of forms; track form.id) {
        <div
          cdkDrag
          class="form-template cursor-pointer"
          pharTooltipPosition="above"
          [cdkDragData]="form"
          [pharTooltipDisabled]="formLeftBlock.scrollWidth <= formLeftBlock.clientWidth"
          [pharTooltip]="form.name">
          <div #formLeftBlock class="form-template_left-block">
            <mat-icon class="form-template_left-block_form-icon" svgIcon="forms" />
            <span class="form-template_name">{{ form.name }}</span>
          </div>

          <div class="form-template_right-block">
            <span>v{{ form.version }}</span>
            <button class="eye-button" mat-icon-button (click)="openFormPreviewDialog(form)">
              <mat-icon svgIcon="eye" />
            </button>
          </div>
          <div *cdkDragPlaceholder class="drop-placeholder"></div>
        </div>
      }
    </mat-nav-list>
  </ng-template>

  <ng-template #openCloseTmp let-state="state">
    @if (state === toggleSidebarSectionState.Open) {
      <mat-icon class="float-right cursor-pointer small-icon" svgIcon="arrow-up" />
    } @else {
      <mat-icon class="float-right cursor-pointer small-icon" svgIcon="arrow-right" />
    }
  </ng-template>
</div>
