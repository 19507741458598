import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { StudyTeamMemberFormComponent } from '../../sites-and-study-team/study-and-study-team-manage/study-team-member-form/study-team-member-form.component';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from '../../../shared/phar-button/phar-button.component';

@Component({
  templateUrl: 'publish-dialog.component.html',
  styleUrl: 'publish-dialog.component.scss',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatIcon,
    MatIconButton,
    StudyTeamMemberFormComponent,
    MatChipListbox,
    MatChipOption,
    FormsModule,
    ButtonComponent,
  ],
})
export class PublishDialogComponent {
  constructor(public dialogRef: MatDialogRef<PublishDialogComponent>) {}
  selectedEnvironment = null;
  ENVIRONMENTS = [
    { id: 'dev', name: 'DEV', disabled: false },
    { id: 'test', name: 'TEST', disabled: true },
    { id: 'stage', name: 'STAGE', disabled: true },
    { id: 'demo/train', name: 'DEMO/TRAIN', disabled: true },
    { id: 'val', name: 'VAL', disabled: true },
    { id: 'prod', name: 'PROD', disabled: true },
  ];
  close(): void {
    this.dialogRef.close();
  }

  proceed(): void {
    this.dialogRef.close(this.selectedEnvironment);
  }
}
