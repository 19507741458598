import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CONTROL_LABELS_MAX_LENGTH } from '../../core/config/app.constants';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { SharedModule } from '../shared.module';
import { BuilderActions } from './builder-actions.enum';
import { JsonPipe } from '@angular/common';
import { BaseComponent } from '../base.class';
import { TranslateIdPipe } from './translate-id-pipe';

@Component({
  selector: 'phar-id-builder',
  templateUrl: 'id-builder.component.html',
  styleUrl: 'id-builder.component.scss',
  standalone: true,
  imports: [ReactiveFormsModule, MatError, MatFormField, MatInput, MatLabel, SharedModule, JsonPipe, TranslateIdPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdBuilderComponent extends BaseComponent implements OnInit {
  form: FormGroup<{ fields: FormArray<any> }>;
  _formula: string;
  @Input() formula: string;

  @Input() set disabled(value: boolean) {
    if (value) {
      this.form?.disable();
    } else {
      this.form?.enable();
    }
  }

  @Input() separator = '@';
  @Input() fields: { field: string; label: string; id: number }[] = [
    { field: 'id', label: 'Study ID', id: 1 },
    { field: 'siteId', label: 'Site ID', id: 2 },
    { field: 'country', label: 'Country', id: 3 },
    { field: 'customField', label: 'Custom field', id: 4 },
  ];
  @Output() idChange = new EventEmitter<string>();

  getDataControls(): FormControl<any>[] {
    return this.form.controls?.fields.controls as FormControl<any>[];
  }

  ngOnInit() {
    this.initFormGroup();
    this.initSubscriptions();
    if (this.formula) {
      this.formulaToFields(this.formula);
    }
  }

  selectOption(item: { field: string }, index: number): void {
    if (item.field !== 'customField') {
      return;
    }

    const ctrl: FormControl<string> = this.getDataControls()[index].get('value') as FormControl<string>;
    ctrl.setValidators(Validators.required);
    ctrl.setValue('example text');
    ctrl.updateValueAndValidity();
  }

  actionHandler(action: BuilderActions, index: number): void {
    switch (action) {
      case BuilderActions.AddAfter:
        this.form.controls.fields.insert(index + 1, this.generateEmptyFormGroup());
        break;
      case BuilderActions.AddBefore:
        this.form.controls.fields.insert(index, this.generateEmptyFormGroup());
        break;
      case BuilderActions.Remove:
        this.form.controls.fields.removeAt(index);
        break;
    }
  }

  isItemDisable(item: { field: string }): boolean {
    if (!this.form.controls.fields.value.length) {
      return false;
    }

    return !!this.form.controls.fields.value.find(f => f.field === item.field);
  }

  private initSubscriptions(): void {
    this.form.valueChanges.subscribe({
      next: () => {
        const validControlValues = this.form.controls.fields.controls.filter(f => f.valid).map(item => item.value);
        this._formula = this.convertDataToString(validControlValues);
        this.idChange.emit(this._formula);
      },
    });
  }

  private formulaToFields(formula: string): { field: string; value: string }[] {
    const fields: string[] = formula.split(this.separator).filter(x => !!x);
    const formArr = [];
    fields.forEach(field => {
      if (this.fields.find(f => f.field === field)) {
        formArr.push(this.generateEmptyFormGroup(field, null));
      } else {
        formArr.push(this.generateEmptyFormGroup('customField', field));
      }
    });

    this.form.controls.fields.clear();
    formArr.forEach(f => {
      this.form.controls.fields.push(f);
    });
    return this.form.value.fields;
  }

  private convertDataToString(data: { field: string; value: string }[]): string {
    return data.map(d => this.getFieldKey(d)).join('');
  }

  private getFieldKey(item: { field: string; value: any }): string {
    return `${this.separator}${item.value ?? item.field}${this.separator}`;
  }

  private generateEmptyFormGroup(field: string | null = null, value: string | null = null) {
    return new FormGroup({
      field: new FormControl<any>(field, [Validators.required]),
      value: new FormControl<any>(value),
      id: new FormControl<any>(new Date().getTime()),
    });
  }

  private initFormGroup(): void {
    this.form = new FormGroup<{ fields: FormArray<any> }>({
      fields: new FormArray([this.generateEmptyFormGroup()]),
    });
  }

  protected readonly labelMaxLength = CONTROL_LABELS_MAX_LENGTH;
  protected readonly BuilderActions = BuilderActions;
}
