<div
  id="file-upload-container"
  pharDnd
  [ngClass]="{ 'p-1': uploadedImage, 'cursor-pointer': !disabled }"
  [disabled]="disabled"
  (click)="imageUpload.click()"
  (fileDropped)="fileChange($event)">
  @if (uploadedImage) {
    <img class="preview-image" alt="logo" [src]="uploadedImage" />
  } @else if (imageSrc) {
    <img class="preview-image" alt="logo" [src]="imageSrc" />
  } @else {
    <img src="assets/images/image-placeholder.svg" alt="logo" />
    <div class="fw-bold mt-2">{{ title }}</div>
    <div id="hint">
      {{ subtitle }}
    </div>
  }
</div>

<input #imageUpload class="d-none" type="file" [disabled]="disabled" (change)="fileChange($event.target.files)" />
