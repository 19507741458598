<div #eventsContainer id="adhoc-container" [class.editor-opened]="isEditorOpened">
  <div id="adhoc-list">
    <ng-content />
    <div class="d-flex flex-row align-items-center w-100">
      <div class="w-100 align-items-center">
        <mat-label class="me-auto px-3 font-weight-bold font-size-lg my-2">Adhoc events</mat-label>
      </div>
      @if (!editLocked()) {
        <div class="d-flex justify-content-end">
          <mat-toolbar class="background-transparent px-4">
            <phar-list-column-selection
              class="ms-3"
              [columns]="config.columns"
              [listComponent]="listViewComponent"
              (columnsSelectionChange)="columnsSelectionChange($event)" />
          </mat-toolbar>
        </div>
      }
    </div>

    <div class="px-3 d-flex flex-column">
      <phar-draggable-list-view
        #listViewComponent
        draggingDisabled="true"
        [config]="config"
        [dataSource]="adhocEventsList()"
        [isRowSelectable]="false"
        [noDataMessage]="'Please click Add above on the right to start creating adhoc events.'"
        [selectedRecordId]="selectedEventId"
        (afterSelect)="handleEdit($event.dataItem.id)" />
    </div>
  </div>
  @if (isEditorOpened) {
    <div class="widgets-container_content_rightbar d-flex flex-column px-0 position-relative" id="adhoc-editor">
      <div
        class="resize-handle"
        [class.resizing]="rightBarResizingEvent.isResizing"
        (mousedown)="startRightBarResizing($event)"></div>
      <button class="events-editor-close-icon" mat-icon-button (click)="closeEditor()">
        <mat-icon class="button-block_small-icon" svgIcon="close" />
      </button>
      <div class="d-flex justify-content-center p-3">
        <button
          class="left-switch-button cursor-pointer"
          mat-button
          [ngClass]="{ 'active-switch-button': activeEditorTab === EventsEditorTabEnum.Properties }"
          (click)="setActiveEditorTab(EventsEditorTabEnum.Properties)">
          Properties
        </button>
        <button
          class="right-switch-button cursor-pointer"
          mat-button
          [ngClass]="{ 'active-switch-button': activeEditorTab === EventsEditorTabEnum.Notifications }"
          [disabled]="!selectedEventId"
          (click)="setActiveEditorTab(EventsEditorTabEnum.Notifications)">
          Notifications ({{ numberOfEventNotifications$ | async }})
        </button>
      </div>
      <div class="flex-1 overflow-auto px-2">
        @switch (activeEditorTab) {
          @case (EventsEditorTabEnum.Properties) {
            <phar-adhoc-event-editor
              [event]="selectedEvent$ | async"
              [disabled]="editLocked()"
              (save)="handleSave()"
              (discard)="closeEditor()" />
          }
          @case (EventsEditorTabEnum.Notifications) {
            <phar-event-notifications-list [event]="selectedEvent$ | async" [disabled]="editLocked()" />
          }
        }
      </div>
    </div>
  }
</div>

<ng-template #elementTypeTemplate let-element>
  <phar-event-type [elementType]="element.elementType" />
</ng-template>

<ng-template #actionsTemplate let-element>
  @let projectEvent = eventToProjectEventMap()[element.id];
  <button
    class="ms-auto list-card_more"
    mat-icon-button
    [matMenuTriggerFor]="actionsMenu"
    (click)="$event.stopImmediatePropagation()">
    <mat-icon class="more">more_vert</mat-icon>
  </button>
  <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
    <button
      class="button-with-icon"
      mat-menu-item
      [disabled]="projectEvent.status | entityActionState: 'updateLocked' : projectStatus() : nextStatus()"
      (click)="actionHandler({ eventName: EventActions.Edit, dataItem: element })">
      <mat-icon svgIcon="edit" />
      <span>Edit</span>
    </button>
    <button
      class="button-with-icon"
      mat-menu-item
      [disabled]="editLocked()"
      (click)="actionHandler({ eventName: EventActions.Duplicate, dataItem: element })">
      <mat-icon svgIcon="duplicate" />
      <span>Duplicate</span>
    </button>
    <button
      class="button-with-icon"
      mat-menu-item
      [disabled]="projectEvent.status | entityActionState: 'commentsLocked' : projectStatus() : nextStatus()"
      (click)="actionHandler({ eventName: EventActions.Comments, dataItem: element })">
      <mat-icon svgIcon="comment" />
      @let commentsCount =
        adHocEventsCommentsState()[projectEvent.id] ? adHocEventsCommentsState()[projectEvent.id].commentCount : 0;
      <span>Comments</span>
      ({{ commentsCount }})
    </button>
    <button
      class="button-with-icon"
      mat-menu-item
      [disabled]="projectEvent.status | entityActionState: 'deleteLocked' : projectStatus() : nextStatus()"
      (click)="actionHandler({ eventName: EventActions.Delete, dataItem: element })">
      <mat-icon svgIcon="trash" />
      <span>Delete</span>
    </button>
  </mat-menu>
</ng-template>

<ng-template #statusTemplate let-element>
  <phar-status-badge [status]="eventToProjectEventMap()[element.id]?.status || Statuses.PendingReview" />
</ng-template>
<ng-template #statusChangeTemplate let-element>
  @let item = eventToProjectEventMap()[element.id];

  <mat-slide-toggle
    #el
    color="primary"
    pharNoPropagation
    [hideIcon]="true"
    [disabled]="item?.status | entityActionState: 'changeStatusLocked' : projectStatus() : nextStatus()"
    [checked]="item?.status | entityActionState: 'changeStatusChecked' : projectStatus() : nextStatus()"
    (click)="handleChangeStatus(element, el)" />
</ng-template>
<ng-template #toggleAllTemplate>
  @if (adhocEventsList().length > 0) {
    <div
      class="header-with-menu cursor-pointer"
      [matMenuTriggerFor]="actionsMenu"
      (click)="$event.stopImmediatePropagation()">
      Confirm All
      <mat-icon class="more">more_vert</mat-icon>
    </div>
    <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
      <div mat-menu-item>
        <mat-slide-toggle
          #el
          color="primary"
          class="w-100"
          [hideIcon]="true"
          [disabled]="isConfirmAllDisabled()"
          [checked]="isConfirmAllChecked()"
          (click)="handleBulkStatusChange(el)"
          >Change all statuses
        </mat-slide-toggle>
      </div>
    </mat-menu>
  }
</ng-template>
<ng-template #commentsTemplate let-element>
  @let item = eventToProjectEventMap()[element.id];
  @let itemCommentsState = adHocEventsCommentsState()[item?.id];
  @let commentsCount = adHocEventsCommentsState()[item?.id] ? adHocEventsCommentsState()[item?.id].commentCount : 0;
  <button
    mat-icon-button
    pharNoPropagation
    [pharTooltip]="'Comments (' + commentsCount + ')'"
    [pharTooltipPosition]="'above'"
    [disabled]="item?.status | entityActionState: 'commentsLocked' : projectStatus() : nextStatus()"
    (click)="actionHandler({ eventName: EventActions.Comments, dataItem: element })">
    <mat-icon
      [svgIcon]="'comment'"
      [ngClass]="{
        'icon-red': itemCommentsState && !itemCommentsState.isAllResolved,
        'icon-blue': itemCommentsState && itemCommentsState.isAllResolved,
      }" />
  </button>
</ng-template>
