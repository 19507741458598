import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportDataService } from './export-data.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ExportDataService],
})
export class ExportDataModule {}
