<ng-container [formGroup]="form">
  <div class="d-flex flex-row">
    <div class="d-flex flex-column">
      <mat-label id="participant-title" class="required-field">Participant Naming Convention Builder</mat-label>
      <div class="d-flex builder-row" formArrayName="fields">
        @for (control of getDataControls(); let i = $index; track control.value.id; let count = $count) {
          <div class="d-flex builder-row-item" [formGroupName]="i">
            @if (control.value.field === 'customField') {
              <mat-form-field>
                <input matInput formControlName="value" placeholder="Custom field" />
              </mat-form-field>
            } @else {
              <mat-form-field>
                <mat-select placeholder="Select Field" formControlName="field">
                  @for (field of fields; track field.field) {
                    <mat-option
                      [value]="field.field"
                      [disabled]="isItemDisable(field)"
                      (click)="selectOption(field, i)">
                      {{ field.label }}
                    </mat-option>
                  }
                </mat-select>
              </mat-form-field>
            }
            @if (form.enabled) {
              <mat-icon class="cursor-pointer mt-2" [matMenuTriggerFor]="actionsMenu">more_vert</mat-icon>
              <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
                <button
                  class="button-with-icon"
                  mat-menu-item
                  [disabled]="fields.length === getDataControls().length"
                  (click)="actionHandler(BuilderActions.AddAfter, i)">
                  <mat-icon svgIcon="plus" />
                  <span>Add after</span>
                </button>

                <button
                  class="button-with-icon"
                  mat-menu-item
                  [disabled]="fields.length === getDataControls().length"
                  (click)="actionHandler(BuilderActions.AddBefore, i)">
                  <mat-icon svgIcon="plus" />
                  <span>Add before</span>
                </button>

                <button
                  class="button-with-icon"
                  mat-menu-item
                  [disabled]="count < 2"
                  (click)="actionHandler(BuilderActions.Remove, i)">
                  <mat-icon svgIcon="trash" />
                  <span>Remove</span>
                </button>
              </mat-menu>
            }
          </div>
        }
      </div>
    </div>
    <ng-content />
  </div>
</ng-container>
