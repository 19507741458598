import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IEventEditorData } from './adhoc-event-editor-form/event-editor-data.interface';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/models/app.state';
import { updateEventFields } from '../store/event.actions';
import { EventModel } from '../event.model';

@Component({
  selector: 'phar-adhoc-event-editor',
  templateUrl: 'adhoc-event-editor.component.html',
  styleUrls: ['adhoc-event-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdhocEventEditorComponent {
  @Input() event: EventModel;
  @Input() disabled = false;
  @Output() discard = new EventEmitter<void>();
  @Output() save: EventEmitter<{ eventName: string; elementType: number }> = new EventEmitter<{
    eventName: string;
    elementType: number;
  }>();

  formData$ = new BehaviorSubject<IEventEditorData>({
    isValid: false,
    values: null,
  });

  constructor(private store: Store<AppState>) {}

  handleFormDataChange($event: IEventEditorData) {
    this.formData$.next($event);
  }

  closeEditor(): void {
    this.discard.emit();
  }

  saveEvent(): void {
    if (!this.formData$.getValue().isValid) {
      return;
    }
    this.store.dispatch(updateEventFields({ event: this.formData$.getValue().values }));
    this.save.emit(this.formData$.getValue().values);
  }
}
