import { Component, computed, effect, inject, Injector, OnDestroy, OnInit, Signal, signal } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { AppState } from '../../store/models/app.state';
import { Store } from '@ngrx/store';
import {
  bulkUpdateProjectMatrixItemsStatuses,
  dispatchedCreateProject,
  getProjectMatrixItems,
  getProjectStatusHistory,
  loadProjectMatrixItemsCommentsState,
  pendingCreateProject,
  publishProject,
  searchProjectById,
  selectCurrentProjectManageProject,
  selectCurrentProjectManageProjectMatrixCommentsState,
  selectCurrentProjectManageProjectMatrixItems,
  selectProjectStatusHistory,
  updateEntitiesStatuses,
  updateProject,
  updateProjectStatus,
} from '../store/project.actions';
import { filter, map, take, tap } from 'rxjs/operators';
import { ProjectModel } from '../project.model';
import { UtilsService } from '../../core/utils.service';
import { globalLoading } from '../../store/actions/ui.actions';
import { BaseComponent } from '../../shared/base.class';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { MatDialog } from '@angular/material/dialog';
import { Statuses } from '../../shared/models/statuses.enum';
import {
  getAssignsByProjectId,
  loadAssignmentsCommentsState,
  updateAssignments,
} from '../../assign/store/assign.actions';
import { loadProjectEventListByProjectId, updateProjectEvents } from '../../events/store/event.actions';
import { toSignal } from '@angular/core/rxjs-interop';
import { selectAssignCommentsState, selectAssignListState } from '../../assign/store/assign.state';
import { selectEventList, selectEventsCommentsState } from '../../events/store/event.state';
import { Router } from '@angular/router';
import { ICommentsState } from '../../shared/models/comments-state.interface';
import { AssignModel } from '../../assign/assign.model';
import { IStudyMatrixItem } from '../../study-matrix/study-matrix-editor/study-matrix-item.interface';
import { ProjectEventModel } from '../../events/project-event.model';
import { PublishDialogComponent } from './publish-dialog/publish-dialog.component';
import { IApprovalStep } from '../../shared/approval-step/approval-step.interface';
import { IProjectStatus } from '../project-status.interface';
import { StudyEntityActionStateService } from '../../shared/services/study-entity-action-state.service';
import { selectLoaders } from '../../store/reducers/ui.reducer';

enum ErrorType {
  NotResolved = 'notResolved',
  NotInStatus = 'notInStatus',
  NoLength = 'noLength',
}

@Component({
  templateUrl: 'study-approvals.component.html',
  styleUrls: ['./study-approvals.component.scss'],
})
export class StudyApprovalsComponent extends BaseComponent implements OnInit, OnDestroy {
  injector: Injector = inject(Injector);
  store: Store<AppState> = inject(Store);
  router: Router = inject(Router);
  utilsService: UtilsService = inject(UtilsService);
  assessments = toSignal(this.store.select(selectAssignListState));
  events = toSignal(
    this.store
      .select(selectEventList)
      .pipe(map((events: ProjectEventModel[]) => events.filter((event: ProjectEventModel) => !event.isBaseline))),
  );
  projectMatrixItems = toSignal(this.store.select(selectCurrentProjectManageProjectMatrixItems));

  assessmentsCommentsState = toSignal(this.store.select(selectAssignCommentsState), {
    injector: this.injector,
  });
  eventsCommentsState = toSignal(this.store.select(selectEventsCommentsState), { injector: this.injector });
  studyMatrixItemsCommentsState = toSignal(
    this.store.select(selectCurrentProjectManageProjectMatrixCommentsState, {
      inject: this.injector,
    }),
  );
  project: Signal<ProjectModel> = toSignal(this.store.select(selectCurrentProjectManageProject), {
    injector: this.injector,
  });

  projectStatuses = toSignal(this.store.select(selectProjectStatusHistory), { injector: this.injector });

  studyEntityActionStateService: StudyEntityActionStateService = inject(StudyEntityActionStateService);

  HISTORY_STATUSES = [
    Statuses.Released,
    Statuses.Published,
    Statuses.RevisionInProgress,
    Statuses.RevisionCompleted,
    Statuses.RevisionRejected,
  ];

  statusHistory: Signal<
    Partial<Record<Statuses, { all: IProjectStatus[]; last: IApprovalStep | null }>> & { history: IApprovalStep[] }
  > = computed(() => {
    // THose are te main statuses, others will be injected dynamically
    const approvalSteps: Partial<Record<Statuses, { all: IProjectStatus[]; last: IApprovalStep | null }>> & {
      history: IApprovalStep[];
    } = {
      [Statuses.Draft]: {
        all: [],
        last: null,
      },
      [Statuses.ReadyForReview]: {
        all: [],
        last: null,
      },
      [Statuses.UnderReview]: {
        all: [],
        last: null,
      },
      history: [],
    };
    // removing Published since we are updating the Released status with environment. Action for start Amendment process is there
    const statuses = this.projectStatuses();
    if (!statuses || !statuses.length) {
      return approvalSteps;
    }

    statuses.reduce(
      (
        acc: Partial<
          Record<Statuses, { all: IProjectStatus[]; last: IApprovalStep | null }> & { history: IApprovalStep[] }
        >,
        item: IProjectStatus,
      ) => {
        if (this.HISTORY_STATUSES.includes(item.status)) {
          acc.history.push(this.generateReleaseHistoryStep(item));
          return acc;
        }

        if (!(item.status in acc)) {
          acc[item.status] = {
            all: [],
            last: null,
          };
        }

        const entity = acc[item.status];
        entity.all.push(item);
        const newStep: IApprovalStep = this.generateReleaseHistoryStep(item);

        if (!entity.last) {
          entity.last = newStep;
        } else {
          if (item.id > entity.last.id) {
            entity.last = newStep;
          }
        }

        return acc;
      },
      approvalSteps,
    );
    if (approvalSteps.history.length) {
      // order the history items by id in case that the BE doesn't order them properly
      const sortedHistory = [...approvalSteps.history].sort((a, b) => a.id - b.id);
      const updatedHistory = sortedHistory
        .map((currentStep, index, allHistorySteps) => {
          const followingStep = allHistorySteps[index + 1];
          return this.getUpdatedStep(currentStep, followingStep);
        })
        .filter(step => !this.isForbiddenStatus(step));
      const lastStep = updatedHistory[updatedHistory.length - 1];
      // set last step as an active step
      // only last RevisionRejected can be done: true initially
      lastStep.active = true;
      // lastStep.done = lastStep.status === Statuses.RevisionRejected;
      if (lastStep.status === Statuses.RevisionRejected) {
        lastStep.done = true;
      }

      approvalSteps.history = updatedHistory;

      // force done all previous
      // let last = approvalSteps.history[approvalSteps.history.length - 1];
      // approvalSteps.history = approvalSteps.history
      //   .map((currentStep, index, allHistorySteps) => {
      //     const followingStep = allHistorySteps[index + 1];
      //     if (
      //       currentStep.status === Statuses.Released &&
      //       followingStep &&
      //       followingStep.status === Statuses.Published
      //     ) {
      //       return {
      //         ...currentStep,
      //         environment: followingStep.environment || 'dev', //@TODO temporary to dev,
      //         done: true,
      //         createdAt: followingStep.createdAt,
      //       };
      //     } else if (
      //       currentStep.status === Statuses.RevisionInProgress &&
      //       followingStep &&
      //       followingStep.status === Statuses.RevisionCompleted
      //     ) {
      //       return {
      //         ...currentStep,
      //         done: true,
      //         createdAt: followingStep.createdAt,
      //       };
      //     } else {
      //       return {
      //         ...currentStep,
      //         active: currentStep.id === last.id,
      //         done: currentStep.id !== last.id,
      //       };
      //     }
      //   })
      //   .filter(step => step.status !== Statuses.Published && step.status !== Statuses.RevisionCompleted);
      // last = approvalSteps.history[approvalSteps.history.length - 1];
      // last.active = true;
      // if (last.status === Statuses.RevisionRejected) {
      //   last.done = true;
      // }

      // last.active = true;
      /// @TODO this needs to be deleted when the BE is done
      // if (last.status === Statuses.Released) {
      //   last.done = true;
      //   last.environment = 'dev';
      // }
    }

    return approvalSteps;
  });

  status$: Observable<Statuses> = this.store.select(selectCurrentProjectManageProject).pipe(
    map((project: ProjectModel) => {
      return project.status.status ?? Statuses.Draft;
    }),
  );
  // isLocked = toSignal(this.store.select(checkCurrentProjectLockedState), {
  //   injector: this.injector
  // });
  //
  isLocked = signal(false);
  isLoading = toSignal(
    this.store.select(
      selectLoaders(
        searchProjectById,
        getAssignsByProjectId,
        loadProjectEventListByProjectId,
        getProjectMatrixItems,
        getProjectStatusHistory,
        loadAssignmentsCommentsState,
        loadProjectMatrixItemsCommentsState,
      ),
    ),
    {
      injector: this.injector,
    },
  );

  //@TODO later this will lock the button according to some permissions

  // releaseHistory: WritableSignal<IApprovalStep[]> = signal([
  //   {
  //     id: 1,
  //     status: Statuses.Released,
  //     label: 'Released',
  //     active: true,
  //     done: true,
  //     icon: 'check',
  //     publishedTo: ['Production'],
  //     updatedAt: new Date().toString(),
  //   },
  //   // {
  //   //   id: 2,
  //   //   status: Statuses.RevisionInProgress,
  //   //   label: 'Revision In Progress',
  //   //   active: true,
  //   //   done: false,
  //   //   icon: 'check',
  //   //   publishedTo: [],
  //   //   updatedAt: new Date().toString(),
  //   // },
  // ]);
  constructor(
    private confirmDialog: PharConfirmDialogService,
    private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit() {
    const loadCommentsEffect = effect(
      () => {
        const projectId = this.project().id;
        if (!projectId) {
          return;
        }
        this.store.dispatch(getAssignsByProjectId({ projectId, addLoader: true }));
        this.store.dispatch(loadProjectEventListByProjectId({ id: projectId, addLoader: true }));
        this.store.dispatch(getProjectMatrixItems({ projectId, addLoader: true }));
        this.store.dispatch(getProjectStatusHistory({ projectId, addLoader: true }));

        loadCommentsEffect.destroy();
      },
      {
        manualCleanup: true,
        injector: this.injector,
        allowSignalWrites: true,
      },
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  generateReleaseHistoryStep(statusEntity: IProjectStatus, active = false, done = false): IApprovalStep {
    if (!statusEntity) {
      return null;
    }
    let data: IApprovalStep = {
      status: statusEntity.status,
      environment: statusEntity.environment || null,
      updatedAt: statusEntity.updatedAt,
      createdAt: statusEntity.createdAt,
      done,
      active,
      id: statusEntity.id,
      label: this.utilsService.getFormattedStatus(statusEntity.status),
      icon: null,
      successClass: '',
      version: statusEntity.versionId || null,
    };

    if (statusEntity.status === Statuses.Rejected || statusEntity.status === Statuses.RevisionRejected) {
      data = {
        ...data,
        icon: 'close',
        successClass: 'rejected',
        done: true,
      };
    }

    return data;
  }

  handleStatusChange(event: { nextStatus: Statuses; step?: IApprovalStep }): void {
    switch (event.nextStatus) {
      case Statuses.PendingReview:
        this.toSendForReviewOrUnderReview(
          Statuses.ReadyForReview,
          Statuses.ReadyForReview,
          Statuses.ReadyForReview,
          false,
        );
        break;

      case Statuses.UnderReview:
        this.toSendForReviewOrUnderReview(
          Statuses.UnderReview,
          Statuses.ReadyForReview,
          Statuses.PendingApproval,
          true,
        );
        break;

      case Statuses.Released:
        this.releaseStudy(event.step);
        break;

      case Statuses.Published:
        this.publishStudy(event.step);
        break;
      case Statuses.RevisionInProgress:
        this.startRevision(event.step);
        break;

      case Statuses.RevisionRejected:
        this.rejectRevision(event.step);
        break;

      case Statuses.RevisionCompleted:
        this.completeRevision(event.step);
        break;

      case Statuses.Rejected:
        this.rejectStudy();
        break;
    }
  }

  private rejectStudy(): void {
    this.confirmDialog
      .openConfirmDialog(`You are about to <b>Reject</b> the Study, Are you sure?`, `Please Confirm`, 'Yes', 'No')
      .pipe(
        take(1),
        filter(x => !!x),
      )
      .subscribe({
        next: () => {
          this.bulkUpdateProjectEntities(Statuses.PendingRevision, Statuses.PendingApproval);
          this.updateProject({ ...this.project() }, Statuses.Rejected);
          this.store.dispatch(updateProjectStatus({ projectId: this.project().id, newStatus: Statuses.Rejected }));
        },
      });
  }

  private publishStudy(step: IApprovalStep): void {
    this.dialog
      .open(PublishDialogComponent, {
        width: '400px',
        autoFocus: false,
        disableClose: true,
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(x => !!x),
      )
      .subscribe({
        next: (environment: string) => {
          // This action also update the status of the project to Published if everything is ok because we are publishing in the other system
          // for the future we can ask from the BE to do that as well but for now we do it manually
          this.store.dispatch(publishProject({ projectId: this.project().id, environment }));
          this.bulkUpdateProjectEntities(Statuses.Active, Statuses.Approved);
        },
      });
  }
  private startRevision(step: IApprovalStep): void {
    this.confirmDialog
      .openConfirmDialog(`This will start the revision process, Are you sure?`, `Please Confirm`, 'Yes', 'No')
      .pipe(
        take(1),
        filter(x => !!x),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(
            //@TODO call amend project API when its done
            updateProjectStatus({ projectId: this.project().id, newStatus: Statuses.RevisionInProgress }),
          );
        },
      });
  }

  private rejectRevision(step: IApprovalStep): void {
    this.confirmDialog
      .openConfirmDialog(`You are about to reject revision, Are you sure?`, `Please Confirm`, 'Yes', 'No')
      .pipe(
        take(1),
        filter(x => !!x),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(
            updateProjectStatus({ projectId: this.project().id, newStatus: Statuses.RevisionRejected }),
          );
        },
      });
  }

  private completeRevision(step: IApprovalStep): void {
    this.confirmDialog
      .openConfirmDialog(`You will complete the revision process, Are you sure?`, `Please Confirm`, 'Yes', 'No')
      .pipe(
        take(1),
        filter(x => !!x),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(
            updateProjectStatus({ projectId: this.project().id, newStatus: Statuses.RevisionCompleted }),
          );
        },
      });
  }
  private bulkUpdateProjectEntities(entitiesNextStatus: Statuses, filterStatus: Statuses): void {
    const assessmentsToUpdate: AssignModel[] = this.getEntitiesWithStatus(this.assessments(), filterStatus);
    const eventsToUpdate: ProjectEventModel[] = this.getEntitiesWithStatus(this.events(), filterStatus);
    const projectMatrixItemsToUpdate: IStudyMatrixItem[] = this.getEntitiesWithStatus(
      this.projectMatrixItems(),
      filterStatus,
    );

    if (assessmentsToUpdate.length > 0) {
      this.store.dispatch(
        updateAssignments({
          assignments: assessmentsToUpdate.map((assign: AssignModel) => {
            return {
              ...assign,
              status: entitiesNextStatus,
            };
          }),
        }),
      );
    }
    if (eventsToUpdate.length > 0) {
      this.store.dispatch(
        updateProjectEvents({
          projectEvents: eventsToUpdate.map((projectEvent: ProjectEventModel) => ({
            ...projectEvent,
            status: entitiesNextStatus,
          })),
        }),
      );
    }

    if (projectMatrixItemsToUpdate.length > 0) {
      this.store.dispatch(globalLoading(true));
      this.store.dispatch(
        bulkUpdateProjectMatrixItemsStatuses({
          projectId: this.project().id,
          newStatus: entitiesNextStatus,
          ids: projectMatrixItemsToUpdate.map((item: IStudyMatrixItem) => item.id),
        }),
      );
    }
  }

  private releaseStudy(step: IApprovalStep): void {
    const entitiesNextStatus = Statuses.Approved;
    const entityErrors: {
      url: string;
      title: string;
      errorType: ErrorType;
    } | null = this.validateStudyEntities(entitiesNextStatus, false);

    if (entityErrors) {
      this.confirmDialog
        .openConfirmDialog(
          `<b>${entityErrors.title}</b> - ${this.getErrorMessage(entityErrors.errorType, entitiesNextStatus)}`,
          'Warning',
          'Go there',
          'Cancel',
        )
        .pipe(
          filter(res => res),
          take(1),
        )
        .subscribe({
          next: () => {
            this.router.navigate([`dashboard/study/edit/${this.project().id}/${entityErrors.url}`]);
          },
        });

      return;
    }

    //@TODO this needs to be revised, maybe it will be changed since we have a full integration with the other system
    // const defaultVersion = '0.1';
    // const nextVersion = this.utilsService.incrementVersion(this.project().projectVersion ?? defaultVersion, 'major');
    this.confirmDialog
      .openConfirmDialog(`Have all reviewers approved the Study?`, `${this.project().name}`, 'Yes', 'No')
      .pipe(
        filter(isConfirmed => !!isConfirmed),
        // version update is happening on the BE level
        // switchMap(() =>
        //   this.confirmDialog.openConfirmDialog(
        //     `Do you want to update the version number to ${nextVersion}?`,
        //     `${this.project().name} V${this.project().projectVersion ?? defaultVersion}`,
        //     'Yes',
        //     'No',
        //   ),
        // ),
        // switchMap(() => {
        //   // if (isConfirmed) {
        //   //   return of(nextVersion);
        //   // }
        //
        //   //updating the project with needed fields. We are passing status only for some additional checks inside the method
        //   // this.updateProject({ ...this.project(), projectVersion: nextVersion }, Statuses.Released);
        //
        //   return this.dialog
        //     .open(VersionInputDialogComponent, {
        //       width: '300px',
        //       data: {
        //         title: `${this.project().name} V${this.project().projectVersion ?? defaultVersion}`,
        //         defaultVersion: this.project().projectVersion ?? defaultVersion,
        //       },
        //     })
        //     .afterClosed();
        // }),
        tap(() => {
          //   // this.updateProject({ ...this.project(), projectVersion: version }, Statuses.Released);
          //   this.store.dispatch(updateProject({ project: { ...this.project(), projectStatus: Statuses.Released } }));
          this.store.dispatch(updateProjectStatus({ projectId: this.project().id, newStatus: Statuses.Released }));
        }),
        switchMap(() => this.status$), // change the flow to get wait for status change
        filter(status => status === Statuses.Released), // filter to get the updates status only
        tap(() => {
          this.confirmDialog.openConfirmDialog('', `${this.project().name} is now released`, 'Got it', '');
        }),
        take(1),
      )
      .subscribe();
  }

  /*
   * After study is send for review the Study goes to UnderReview, items goes to PendingApproval
   */
  private toSendForReviewOrUnderReview(
    projectNextStatus: Statuses,
    entitiesValidationStatus: Statuses,
    entitiesNextStatus: Statuses,
    updateEntities: boolean,
  ): void {
    const entityErrors: {
      url: string;
      title: string;
      errorType: ErrorType;
    } | null = this.validateStudyEntities(entitiesValidationStatus);

    if (entityErrors) {
      this.confirmDialog
        .openConfirmDialog(
          `<b>${entityErrors.title}</b> - ${this.getErrorMessage(entityErrors.errorType, entitiesNextStatus)}`,
          'Warning',
          'Go there',
          'Cancel',
        )
        .pipe(
          filter(res => res),
          take(1),
        )
        .subscribe({
          next: () => {
            this.router.navigate([`dashboard/study/edit/${this.project().id}/${entityErrors.url}`]);
          },
        });

      return;
    }

    this.confirmDialog
      .openConfirmDialog(
        `Are you sure you want to proceed? The status of the study will be updated to <b>${this.utilsService.getFormattedStatus(projectNextStatus)}</b>`,
        'Please Confirm',
      )
      .pipe(
        filter(result => !!result),
        take(1),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(updateProjectStatus({ projectId: this.project().id, newStatus: projectNextStatus }));

          if (updateEntities) {
            if (this.project().rejectionDate) {
              this.bulkUpdateProjectEntities(entitiesNextStatus, Statuses.ReadyForReview);
              return;
            }
            this.store.dispatch(
              updateEntitiesStatuses({
                projectId: this.project().id,
                newStatus: entitiesNextStatus,
              }),
            );
          }
        },
      });
  }

  private getDateField(projectStatus: Statuses): Record<string, string> | null {
    const date = new Date().toJSON();
    switch (projectStatus) {
      case Statuses.PendingReview:
        return { dateSentForReview: date };
      case Statuses.Rejected:
        return { rejectionDate: date };
      case Statuses.UnderReview:
        return { dateReviewStarted: date };
      case Statuses.Released:
        return { releaseDate: date };
      case Statuses.Amended:
        return { amendedDate: date };
      default:
        return null;
    }
  }

  private updateProject(projectData: ProjectModel, projectStatus: Statuses): void {
    const dateField = this.getDateField(projectStatus);
    let project = {
      ...projectData,
    };

    if (dateField) {
      project = {
        ...project,
        ...dateField,
      };
    }
    this.utilsService.dispatchActions(this.store, [
      globalLoading(true),
      pendingCreateProject({ pending: true }),
      dispatchedCreateProject({ dispatched: true }),
      updateProject({ project }),
    ]);
  }

  validateStudyEntities(
    status: Statuses,
    skipComments = true,
  ): { title: string; url: string; errorType: ErrorType } | null {
    const assessmentsError = this.checkEntity(
      Object.values(this.assessmentsCommentsState()),
      this.assessments(),
      status,
      skipComments,
    );

    if (assessmentsError) {
      return { title: 'Assessments', url: 'assessments', errorType: assessmentsError };
    }

    const projectEventsError = this.checkEntity(
      Object.values(this.eventsCommentsState()),
      this.events(),
      status,
      skipComments,
    );
    if (projectEventsError) {
      return { title: 'Events', url: 'events', errorType: projectEventsError };
    }

    const studyMatrixItemsError = this.checkEntity(
      Object.values(this.studyMatrixItemsCommentsState()),
      this.projectMatrixItems(),
      status,
      skipComments,
    );

    if (studyMatrixItemsError) {
      return { title: 'Schedule of assessments', url: 'schedule-assessments', errorType: studyMatrixItemsError };
    }

    return null;
  }

  /**
   * Check entity if it's ready for the next status
   * its return ErrorType if fails and null if everything is ok
   * @param comments
   * @param entities
   * @param status
   * @param skipCommentsCheck
   * @private
   */
  private checkEntity<
    T extends {
      status: Statuses;
    },
  >(comments: ICommentsState[], entities: T[], status: Statuses, skipCommentsCheck = true): ErrorType | null {
    if (!skipCommentsCheck) {
      if (comments.some(state => !state.isAllResolved)) return ErrorType.NotResolved;
    }
    if (entities.length === 0) return ErrorType.NoLength;
    /*.filter(item => item.status !== Statuses.Approved).*/
    if (entities.some(item => !this.studyEntityActionStateService.isEntityChangeStatusChecked(item.status, status))) {
      return ErrorType.NotInStatus;
    }

    return null;
  }

  private getErrorMessage(errorType: ErrorType, entitiesNextStatus: Statuses): string {
    switch (errorType) {
      case ErrorType.NotResolved:
        return 'All comments must be resolved.';
      case ErrorType.NotInStatus:
        return `All statuses must be in <b>${this.utilsService.getFormattedStatus(entitiesNextStatus)}</b> status`;
      case ErrorType.NoLength:
        return 'Must have at least one record.';
    }
  }

  private getEntitiesWithStatus<T extends { status: Statuses }>(entities: T[], status: Statuses): T[] {
    return entities.filter(item => item.status === status);
  }

  private getUpdatedStep(currentStep: IApprovalStep, followingStep: IApprovalStep): IApprovalStep {
    if (currentStep.status === Statuses.Released && followingStep && followingStep.status === Statuses.Published) {
      return {
        ...currentStep,
        environment: followingStep.environment || 'dev',
        done: true,
        createdAt: followingStep.createdAt,
      };
    } else if (currentStep.status === Statuses.RevisionInProgress) {
      // if there is another step this should be mark as done
      if (followingStep && followingStep.status === Statuses.RevisionCompleted) {
        return {
          ...currentStep,
          done: true,
          createdAt: followingStep.createdAt,
        };
      } else {
        // if there is no another step, current should mark as done: false to be able to show REVISION COMPLETED button
        return {
          ...currentStep,
          active: false,
          done: false,
        };
      }
    } else {
      return {
        ...currentStep,
        active: false,
        done: true,
      };
    }
  }

  private isForbiddenStatus(step: IApprovalStep): boolean {
    return (
      step.status === Statuses.Published ||
      step.status === Statuses.RevisionCompleted ||
      step.status === Statuses.Rejected
    );
  }

  protected readonly Statuses = Statuses;
}
