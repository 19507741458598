/* src/app/project/study-approvals/study-approval-steps/study-approval-steps.component.scss */
phar-approval-step ::ng-deep .approval.rejected .approval_info_badge {
  color: white;
  background-color: #c74940;
}
phar-approval-step ::ng-deep .approval.rejected .approval_info_label {
  color: #c74940;
}
#steps-container {
  row-gap: 10px;
}
#steps-container .step-content {
  padding-left: 0.75rem;
}
.published-to {
  color: white;
  background: #499f68;
  border-radius: 5px;
  padding: 5px;
  text-transform: uppercase;
}
/*# sourceMappingURL=study-approval-steps.component.css.map */
