<div id="form-container" class="overflow-hidden" [formGroup]="form">
  <div id="project-details" class="ps-3 py-3 container">
    <h4>General Information</h4>
    <div class="row">
      <div class="col-4">
        <mat-label class="required-field">Study ID</mat-label>
        <mat-form-field>
          <input
            matInput
            pharInputTrim
            formControlName="shortName"
            placeholder="Study ID / Short Name"
            [maxlength]="250"
            [pharMaxLengthTooltip]="250" />

          <mat-error> Study ID is <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-label class="required-field">Short Name</mat-label>
        <mat-form-field>
          <input
            matInput
            pharInputTrim
            formControlName="name"
            placeholder="Study Name"
            [maxlength]="250"
            [pharMaxLengthTooltip]="250" />

          <mat-error>
            @if (form.get('name').hasError('required')) {
              Short Name is <strong>required</strong>
            } @else if (form.get('name').hasError('maxlength')) {
              Short Name maximum length is <strong>500</strong>
            }
          </mat-error>
        </mat-form-field>
      </div>

      <!--      <div class="col-2">-->
      <!--        <mat-label class="required-field">Version</mat-label>-->
      <!--        <mat-form-field>-->
      <!--          <input formControlName="projectVersion" matInput placeholder="Version" type="string" />-->
      <!--          <mat-error>-->
      <!--            @if (form.get('projectVersion').hasError('required')) {-->
      <!--              Version is <strong>required</strong>-->
      <!--            } @else if (form.get('projectVersion').hasError('pattern')) {-->
      <!--              The Version must be in the following format <strong>x.x.x</strong>-->
      <!--            }-->
      <!--          </mat-error>-->
      <!--        </mat-form-field>-->
      <!--      </div>-->
    </div>
    <div class="row">
      <div class="col-10">
        <mat-label class="required-field">Protocol Title</mat-label>
        <mat-form-field>
          <input formControlName="displayName" matInput placeholder="Enter Protocol Title" />
          <mat-error> Protocol title is <strong>required</strong> </mat-error>
        </mat-form-field>
      </div>
    </div>



    <!--    <mat-label>Study Description</mat-label>-->
    <!--    <mat-form-field>-->
    <!--      <textarea-->
    <!--        matInput-->
    <!--        formControlName="description"-->
    <!--        placeholder="Description"-->
    <!--        cdkTextareaAutosize-->
    <!--        cdkAutosizeMinRows="1"-->
    <!--        cdkAutosizeMaxRows="20"-->
    <!--        [maxlength]="500"-->
    <!--        [pharMaxLengthTooltip]="500">-->
    <!--      </textarea>-->
    <!--    </mat-form-field>-->

    <div class="col-5">
      <mat-label [ngClass]="{ 'required-field': !newSponsorMode }">Name of Sponsor</mat-label>
      <mat-form-field>
        <input type="text" matInput formControlName="sponsorName" [placeholder]="'Select Sponsor'" />
        <!--        <input-->
        <!--          type="text"-->
        <!--          matInput-->
        <!--          [formControl]="sponsorFormCtrl"-->
        <!--          [placeholder]="newSponsorMode ? 'New' : 'Select Sponsor'"-->
        <!--          [matAutocomplete]="auto" />-->
        <!--        <mat-autocomplete #auto="matAutocomplete" class="pb-0" [displayWith]="displayFn">-->
        <!--          <div class="custom-panel">-->
        <!--            @for (option of filteredSponsors$ | async; track option.id) {-->
        <!--              <mat-option [value]="option">{{ option.name }}</mat-option>-->
        <!--            }-->
        <!--            <footer-->
        <!--              id="apply-values"-->
        <!--              class="d-flex align-center justify-content-center w-100 cursor-pointer"-->
        <!--              matRipple-->
        <!--              (click)="addNewSponsor(auto)">-->
        <!--              <mat-icon [svgIcon]="'check'" />-->
        <!--              <b>Add new</b>-->
        <!--            </footer>-->
        <!--          </div>-->
        <!--        </mat-autocomplete>-->
        <mat-error> Name of the Sponsor is <strong>required</strong> </mat-error>
      </mat-form-field>
    </div>

    <!--    @if (newSponsorMode) {-->
    <!--      <div class="col-5">-->
    <!--        <mat-form-field>-->
    <!--          <input-->
    <!--            matInput-->
    <!--            pharInputTrim-->
    <!--            formControlName="newSponsorName"-->
    <!--            placeholder="Enter sponsor name"-->
    <!--            [maxlength]="250"-->
    <!--            [pharMaxLengthTooltip]="250" />-->

    <!--          <mat-error> New sponsor name is <b>required</b> </mat-error>-->
    <!--        </mat-form-field>-->
    <!--      </div>-->
    <!--    }-->

    <!--    <phar-file-upload [imageSrc]="sponsor?.image" [disabled]="form.disabled" />-->

    @if (originalProject$ | async; as originalProject) {
      <div class="d-flex align-center">
        <mat-label>Duplicated study:</mat-label>
        <div
          class="original-project-badge d-flex justify-content-center align-center"
          matTooltipPosition="above"
          [matTooltip]="originalProject.name"
          [matTooltipDisabled]="originalProjectName.scrollWidth <= originalProjectName.clientWidth"
          (click)="originalProjectClick(originalProject)">
          <mat-icon class="original-project-badge_icon" svgIcon="duplicate" />
          <span #originalProjectName class="original-project-badge_project-name">
            {{ originalProject.name }}
          </span>
        </div>
      </div>
    }

    @if (amendProject$ | async; as amendProject) {
      <div class="d-flex align-center mt-3">
        <mat-label>First parent:</mat-label>
        <div
          class="amend-project-badge d-flex justify-content-center align-center"
          matTooltipPosition="above"
          [matTooltip]="amendProject.name"
          [matTooltipDisabled]="amendProjectName.scrollWidth <= amendProjectName.clientWidth"
          (click)="originalProjectClick(amendProject)">
          <mat-icon class="amend-project-badge_icon" svgIcon="change" />
          <span #amendProjectName class="amend-project-badge_project-name">
            {{ amendProject.name }}
          </span>
        </div>
      </div>
    }
  </div>
  <!--  <div id="delimiter"></div>-->
  <!--  <div id="settings" class="ps-3 py-3">-->
  <!--    <h4>Settings</h4>-->
  <!--    <div class="row">-->
  <!--      <div class="col-5">-->
  <!--        <mat-label class="required-field"> Date Format</mat-label>-->
  <!--        <mat-form-field>-->
  <!--          <mat-select formControlName="dateFormat" placeholder="Enter Desired Date Format">-->
  <!--            @for (dateFormat of dateFormats; track $index) {-->
  <!--              <mat-option [value]="dateFormat.value">-->
  <!--                {{ dateFormat.label }}-->
  <!--              </mat-option>-->
  <!--            }-->
  <!--          </mat-select>-->
  <!--          <mat-error> Date format is <b>required</b> </mat-error>-->
  <!--        </mat-form-field>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="row">-->
  <!--      <div class="col-12">-->
  <!--        <phar-id-builder [formula]="testFormula" [disabled]="form.disabled" (idChange)="testFormula = $event">-->
  <!--          <div class="number-to-start ms-2">-->
  <!--            <mat-label-->
  <!--              >Numbering start-->
  <!--              <phar-help-icon-->
  <!--                helpText="the numbering scheme chosen determines the maximum number of participants allowed.  ex. 1 = Max 9, 001 = max of 999. " />-->
  <!--            </mat-label>-->
  <!--            <mat-form-field>-->
  <!--              <mat-select formControlName="numberingStart">-->
  <!--                @for (field of numbersToStart; track $index) {-->
  <!--                  <mat-option [value]="field.value" [pharTooltip]="field.tooltip">-->
  <!--                    {{ field.label }}-->
  <!--                  </mat-option>-->
  <!--                }-->
  <!--              </mat-select>-->
  <!--              <mat-error> Numbering start is <b>required</b> </mat-error>-->
  <!--            </mat-form-field>-->
  <!--          </div>-->
  <!--        </phar-id-builder>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="row">-->
  <!--      <div class="col-12">-->
  <!--        <mat-label>Preview</mat-label>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="row">-->
  <!--      <div class="col-12 pt-2">-->
  <!--        {{ (testFormula | pharTranslateId: testEntity : '@' : '_') + '_' + form.controls.numberingStart.value }}-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
</div>
<div id="form-actions" class="d-flex align-items-center px-3">
  @if (currentProject$ | async; as project) {
    @if (project.status.status === Statuses.Rejected || project.status.status === Statuses.Amended) {
      <button class="btn-small button-success" mat-raised-button (click)="returnToDraftAndUnlockProject()">
        <mat-icon class="me-2" svgIcon="change" />
        <b>Return to Draft</b>
      </button>
    }
  }
  <!--  (hasPendingChanges$ | async) === false || isLocked || (globalLoading$ | async)-->
  <button mat-raised-button class="ms-auto btn-small button-success" [disabled]="true" (click)="addEditProject()">
    <mat-icon [svgIcon]="isNewProject ? 'plus' : 'check'" />
    <b>Save</b>
  </button>
</div>
