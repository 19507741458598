import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Injector,
  input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ElementTypeEnum } from '../enums/element-type.enum';
import { Observable } from 'rxjs';
import { EventGroupModel, EventModel } from '../event.model';
import { DraggableListViewConfigModel } from '../../shared/draggable-list-view/draggable-list-view-model';
import { AppState, getState } from '../../store/models/app.state';
import { select, Store } from '@ngrx/store';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { ProjectEventModel } from '../project-event.model';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { UtilsService } from '../../core/utils.service';
import { EventActions } from '../enums/event-actions.enum';
import {
  changeCurrentProjectEvent,
  createProjectEvent,
  deleteProjectEvent,
  dispatchedCreateUpdateEvent,
  loadEventNotificationsListByEventId,
  loadProjectEventListByProjectId,
  loadProjectEventsCommentsState,
  pendingCreateUpdateEvent,
  resetCurrentProjectEvent,
  resetEventNotificationsList,
  successCreateUpdateEvent,
  updateProjectEvent,
  updateProjectEvents,
} from '../store/event.actions';
import {
  CurrentEventManage,
  selectCurrentEventProjectEvent,
  selectEventsCommentsState,
  selectListEventNotificationsState,
} from '../store/event.state';
import { cloneDeep, orderBy } from 'lodash-es';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { ADHOC_EVENTS_TYPES } from '../../core/config/app.constants';
import { BaseComponent } from '../../shared/base.class';
import { EventsEditorTabEnum } from '../events/events.component';
import { environment } from '../../../environments/environment';
import { ResizingEvent } from '../../shared/models/resizing-event.interface';
import { EventCommentDialogComponent } from '../event-comments/event-comment.dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { COMMENTS_DIALOG_CONFIG } from '../../shared/entity-comments/entity-comment-base/base-comments-component.config';
import { EntityType } from '../../core/models/entity-type-enum';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { toSignal } from '@angular/core/rxjs-interop';
import { ProjectService } from '../../project/project.service';
import { Statuses } from '../../shared/models/statuses.enum';
import { ICommentsState } from '../../shared/models/comments-state.interface';
import { ProjectModel } from '../../project/project.model';
import {
  checkProjectHasBeenRejected,
  selectCurrentProjectManageProject,
  selectCurrentProjectStatusStatus,
} from '../../project/store/project.actions';
import { StudyEntityActionStateService } from '../../shared/services/study-entity-action-state.service';

@Component({
  selector: 'phar-adhoc-events-list',
  templateUrl: 'adhoc-events-list.component.html',
  styleUrls: ['adhoc-events-list.component.scss'],
})
export class AdhocEventsListComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('elementTypeTemplate', { static: true }) elementTypeTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('statusChangeTemplate', { static: true }) statusChangeTemplate: TemplateRef<any>;
  @ViewChild('toggleAllTemplate', { static: true }) toggleAllTemplate: TemplateRef<any>;
  @ViewChild('commentsTemplate', { static: true }) commentsTemplate: TemplateRef<any>;
  editLocked = input(false);
  @ViewChild('eventsContainer', { static: true }) eventsContainer: ElementRef;
  @Output() duplicateEvent: EventEmitter<EventModel> = new EventEmitter<EventModel>();
  store: Store<AppState> = inject(Store);
  injector: Injector = inject(Injector);
  projectService: ProjectService = inject(ProjectService);
  project: Signal<ProjectModel> = toSignal(this.store.select(selectCurrentProjectManageProject), {
    injector: this.injector,
  });
  projectEvents = toSignal(
    this.store.select(state => state.event.listProjectEvents),
    { injector: this.injector },
  );
  currentEvent = toSignal(this.store.select(selectCurrentEventProjectEvent), { injector: this.injector });
  projectEventsCommentsCounters = toSignal(this.store.select(selectEventsCommentsState), { injector: this.injector });
  allCommentsResolved = computed(() => {
    if (!Object.values(this.adHocEventsCommentsState()).length) {
      return true;
    }
    return Object.values(this.adHocEventsCommentsState()).every((comments: ICommentsState) => comments.isAllResolved);
  });
  adhocEventsList = toSignal(
    this.store
      .select(state => state.event.listProjectEvents)
      .pipe(
        map(projectEventList => {
          return projectEventList
            .filter((projectEvent: ProjectEventModel) => projectEvent.event !== null)
            .map((projectEvent: ProjectEventModel) => ({ ...projectEvent.event }));
        }),
        map((events: EventModel[]) => {
          return events.filter((event: EventModel) => ADHOC_EVENTS_TYPES.includes(event.elementType));
        }),
        map((events: EventModel[]) => {
          return orderBy(events, ['id'], 'asc');
        }),
      ),
    { injector: this.injector },
  );
  eventToProjectEventMap = computed(() => {
    const eventToProjectEventMapping = {};
    this.projectEvents().forEach(projectEvent => {
      // its needed because we are using projectEvent.id for comments,but in the actions template we are receiving event.id
      eventToProjectEventMapping[projectEvent.eventId] = projectEvent;
    });
    return eventToProjectEventMapping;
  });
  adHocEventsCommentsState = computed(() => {
    const refinedCommentsStates = {};

    this.adhocEventsList().forEach(event => {
      const projectEvent = this.eventToProjectEventMap()[event.id];
      if (!this.projectEventsCommentsCounters()[projectEvent.id]) {
        return;
      }
      refinedCommentsStates[projectEvent.id] = this.projectEventsCommentsCounters()[projectEvent.id];
    });
    return refinedCommentsStates;
  });
  projectHasBeenRejected = toSignal(this.store.select(checkProjectHasBeenRejected), {
    injector: this.injector,
  });

  projectStatus = toSignal(this.store.select(selectCurrentProjectStatusStatus), {
    injector: this.injector,
  });
  nextStatus = computed(() => {
    return this.utilsService.getNextStatus(this.project());
  });
  prevStatus = computed(() => {
    if (this.projectHasBeenRejected()) {
      return Statuses.PendingRevision;
    }
    return this.utilsService.getNextStatus(this.project(), true);
  });

  isConfirmAllDisabled = computed(() => {
    return this.studyEntityActionStateService.isBulkStatusChangeLocked(
      this.adhocEventsList().map(item => {
        return this.eventToProjectEventMap()[item.id].status;
      }),
      this.projectStatus(),
      this.nextStatus(),
    );
  });
  isConfirmAllChecked = computed(() => {
    return this.studyEntityActionStateService.isBulkStatusChangeChecked(
      this.adhocEventsList().map(item => {
        return this.eventToProjectEventMap()[item.id].status;
      }),
      this.nextStatus(),
    );
  });
  private readonly rightBarMinWidth = 270;
  private readonly rightBarMaxWidth = window.innerWidth / 2;

  @HostListener('window:mousemove', ['$event'])
  private updateSidebarWidth(event: MouseEvent) {
    if (!this.rightBarResizingEvent.isResizing) {
      return;
    }

    const cursorDeltaX = this.rightBarResizingEvent.startingCursorX - event.clientX;
    const newWidth = this.rightBarResizingEvent.startingWidth + cursorDeltaX;

    this.setRightBarWidth(newWidth);
  }

  @HostListener('window:mouseup')
  private stopSidebarResizing() {
    this.rightBarResizingEvent.isResizing = false;
  }

  rightBarResizingEvent: ResizingEvent = {
    isResizing: false,
    startingCursorX: 0,
    startingWidth: 0,
  };
  isEditorOpened = false;
  selectedEventId: number;
  activeEditorTab: EventsEditorTabEnum = EventsEditorTabEnum.Properties;

  numberOfEventNotifications$: Observable<number>;
  selectedEvent$: Observable<EventModel>;
  readonly config: DraggableListViewConfigModel = {
    columns: [
      {
        field: 'id',
        size: '0',
        title: 'ID',
        show: false,
        hasTemplate: false,
        template: null,
      },
      {
        field: 'expand',
        size: '40px',
        title: '',
        show: true,
        hasTemplate: false,
        template: null,
      },
      {
        field: 'eventName',
        title: 'Event Name',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: false,
        selectable: true,
        template: null,
      },
      {
        field: 'elementType',
        title: 'Event Type',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'userIdUpdated', // @TODO change this to status column when its done
        title: 'Status',
        size: '120px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'orderPriority', // used for change status placeholder
        title: 'Change status',
        size: '100px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
        hasHeaderCellTemplate: true,
      },
      {
        field: 'projectEventGuid', // field is used only for placeholder
        title: 'Comments',
        size: '80px',
        show: true,
        hasTemplate: true,
        template: null,
      },
      {
        field: 'userIdCreated', // field is used only for placeholder
        title: 'Actions',
        size: '50px',
        // Add as many pixels as left margin added for nested columns (2rem).
        // sizeNested: '86px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
    ],
    actions: [],
    customClasses: [],
  };
  protected readonly ElementType = ElementTypeEnum;
  protected readonly EventsEditorTabEnum = EventsEditorTabEnum;
  private eventState$: Observable<CurrentEventManage>;

  constructor(
    private utilsService: UtilsService,
    private confirmationService: PharConfirmDialogService,
    private dialog: MatDialog,
    private studyEntityActionStateService: StudyEntityActionStateService,
  ) {
    super();
  }

  ngOnInit() {
    this.selectedEventId = getState(this.store).event.current.projectEvent.event.id;

    this.store
      .pipe(
        select(state => state.project.current.project.id),
        filter(id => !!id),
        takeUntil(this.destroy$),
      )
      .subscribe(id => {
        this.store.dispatch(loadProjectEventListByProjectId({ id: id }));
      });

    this.eventState$ = this.store.select(state => state.event.current);
    this.selectedEvent$ = this.store.select(selectCurrentEventProjectEvent);
    this.numberOfEventNotifications$ = this.store
      .select(selectListEventNotificationsState)
      .pipe(map(eventNotifications => eventNotifications.length));

    this.loadEventNotificationsOnSelectEvent();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  ngAfterViewInit() {
    const templates = {
      elementType: this.elementTypeTemplate,
      userIdCreated: this.actionsTemplate,
      userIdUpdated: this.statusTemplate,
      orderPriority: this.statusChangeTemplate,
      'orderPriority@header': this.toggleAllTemplate,
      projectEventGuid: this.commentsTemplate,
    };
    if (this.editLocked()) {
      delete templates.userIdCreated;
      this.config.columns = this.config.columns.filter(item => item.field !== 'userIdCreated');
    }
    this.config.columns = this.utilsService.setColumnTemplate<ListViewColumnModel>(
      this.config.columns,
      templates,
      'field',
    );
    if (this.selectedEventId) {
      this.scrollToElement(this.selectedEventId);
    }
  }

  addNewEvent() {
    if (this.editLocked()) {
      return;
    }
    this.selectedEventId = null;
    this.store.dispatch(resetCurrentProjectEvent());
    this.openEditor();
  }

  handleSave() {
    let projectEvent: ProjectEventModel = {
      ...getState(this.store).event.current.projectEvent,
    };

    this.store.dispatch(pendingCreateUpdateEvent({ pending: true }));
    this.store.dispatch(dispatchedCreateUpdateEvent({ dispatched: true }));
    if (!projectEvent.id) {
      projectEvent = {
        ...projectEvent,
        projectId: this.project().id,
        status: this.prevStatus(),
        event: {
          ...projectEvent.event,
          start: 0,
        },
      };
      this.store.dispatch(createProjectEvent({ projectEvent }));
    } else {
      if (this.projectStatus() === Statuses.RevisionInProgress && projectEvent?.status !== Statuses.New) {
        projectEvent.status = Statuses.PendingUpdate;
      }
      this.store.dispatch(updateProjectEvent({ projectEvent }));
    }

    this.eventState$
      .pipe(
        filter(({ dispatched, success }) => dispatched && success),
        tap(() => {
          this.store.dispatch(pendingCreateUpdateEvent({ pending: false }));
          this.store.dispatch(successCreateUpdateEvent({ success: false }));
          this.closeEditor();
        }),
        take(1),
      )
      .subscribe();
  }

  openEditor() {
    this.isEditorOpened = true;
  }

  closeEditor() {
    this.isEditorOpened = false;
    this.selectedEventId = null;
    this.activeEditorTab = EventsEditorTabEnum.Properties;
    this.store.dispatch(resetCurrentProjectEvent());
    this.eventsContainer.nativeElement.style.removeProperty('--editor-width');
  }

  actionHandler($event: { eventName: EventActions; dataItem: EventGroupModel }): void {
    const { eventName, dataItem } = $event;
    switch (eventName) {
      case EventActions.Edit:
        this.handleEdit(dataItem.id);
        break;
      case EventActions.Delete:
        this.handleDelete(dataItem.eventName, dataItem.id);
        break;
      case EventActions.Duplicate:
        this.handleDuplicateEvent(dataItem);
        break;
      case EventActions.Comments:
        this.openCommentsDialog(dataItem);
        break;
      default:
        break;
    }
  }

  openCommentsDialog(event: EventModel): void {
    const projectEvent = this.projectEvents().find(item => item.eventId === event.id);
    if (!projectEvent) {
      return;
    }
    this.dialog
      .open(EventCommentDialogComponent, {
        data: {
          projectEventId: projectEvent.id,
        },
        ...COMMENTS_DIALOG_CONFIG,
      })
      .afterClosed()
      .pipe(filter(shouldRefreshComments => shouldRefreshComments))
      .subscribe({
        next: () => {
          this.store.dispatch(loadProjectEventsCommentsState({ projectId: this.project().id }));
          // if (projectEvent.status === this.nextStatus()) {
          //   this.store.dispatch(updateProjectEvent({ projectEvent: { ...projectEvent, status: this.prevStatus() } }));
          // }
        },
      });
  }

  setActiveEditorTab(tab: EventsEditorTabEnum): void {
    this.activeEditorTab = tab;
  }

  handleEdit(id: number): void {
    const projectEvent = this.eventToProjectEventMap()[id];
    // approved elements are not allowed for editing
    if (
      this.editLocked() ||
      (this.projectHasBeenRejected() && projectEvent.status === Statuses.Approved) ||
      projectEvent.status === this.nextStatus()
    ) {
      return;
    }

    this.selectedEventId = id;
    this.store.dispatch(changeCurrentProjectEvent({ eventId: id }));
    this.openEditor();
  }

  startRightBarResizing(event: MouseEvent): void {
    this.rightBarResizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      startingWidth: this.getRightBarWidth(),
    };
  }

  scrollToElement(elementId: number, prefix = 'item-'): void {
    // try {
    //   this.utilsService.waitForElementToExist(`#${prefix}${elementId}`).then((element: HTMLElement) => {
    //     (element as HTMLElement).scrollIntoView({ behavior: 'smooth' });
    //   });
    // } catch (e) {
    //   if (!environment.production) {
    //     console.warn(e);
    //   }
    // }
  }

  columnsSelectionChange(columns: ListViewColumnModel[]): void {
    this.config.columns = columns;
  }

  handleChangeStatus(element: EventModel, el: MatSlideToggle): void {
    const projectEvent: ProjectEventModel = this.eventToProjectEventMap()[element.id];
    if (!el.checked || !projectEvent) {
      el.checked = !el.checked;
      return;
    }

    // const itemMeta = this.adHocEventsCommentsState()[projectEvent.id];
    // if (itemMeta && !itemMeta.isAllResolved) {
    //   this.confirmationService
    //     .openConfirmDialog('All comments should be resolved', 'Warning', 'Ok', '')
    //     .pipe(take(1))
    //     .subscribe();
    //   el.checked = false;
    //
    //   return;
    // }
    if (projectEvent.status === Statuses.PendingDeletion) {
      this.confirmationService
        .openConfirmDialog('Do you want to remove ' + projectEvent.event.eventName + '?')
        .pipe(
          take(1),
          filter(isConfirmed => isConfirmed),
        )
        .subscribe(() => {
          this.store.dispatch(deleteProjectEvent({ id: projectEvent.id }));
        });
    } else {
      this.store.dispatch(updateProjectEvent({ projectEvent: { ...projectEvent, status: this.nextStatus() } }));
    }

    this.closeEditorIfEventOpened(projectEvent.id);
  }

  handleBulkStatusChange(el: MatSlideToggle): void {
    if (!el.checked) {
      el.checked = true;
      return;
    }

    // if (!this.allCommentsResolved()) {
    //   this.confirmationService
    //     .openConfirmDialog('All events comments should be resolved', 'Warning', 'Ok', '')
    //     .pipe(take(1))
    //     .subscribe();
    //   el.checked = false;
    //
    //   return;
    // }

    const adHocProjectEvents: ProjectEventModel[] = this.adhocEventsList()
      .filter(el => el.elementType !== this.ElementType.Baseline)
      .map(projectEvent => this.eventToProjectEventMap()[projectEvent.id])
      .filter(
        ({ status }) => !this.studyEntityActionStateService.isEntityChangeStatusChecked(status, this.nextStatus()),
      );

    this.confirmationService
      .openConfirmDialog(
        `You are about to move <b>${adHocProjectEvents.length}</b> records in status <b>
      ${this.utilsService.getFormattedStatus(this.nextStatus())}
</b>. Are you sure?`,
        'Please confirm',
        'Ok',
        'Cancel',
        true,
        300,
        true,
      )
      .pipe(
        take(1),
        map(result => !!result),
        tap(result => {
          if (!result) {
            el.checked = false;
          }
        }),
        filter(canChangeStatuses => canChangeStatuses),
      )
      .subscribe({
        next: () => {
          //TODO: delete pending deletion events. Confirm button is disabled if there are pending deletion now
          const updatedProjectEvents = cloneDeep(adHocProjectEvents).map(projectEvent => ({
            ...projectEvent,
            status: this.nextStatus(),
          }));
          this.store.dispatch(updateProjectEvents({ projectEvents: updatedProjectEvents }));
        },
      });
  }

  private loadEventNotificationsOnSelectEvent(): void {
    this.selectedEvent$
      .pipe(
        map(selectedEvent => selectedEvent.id),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(selectedEventId => {
        this.setActiveEditorTab(EventsEditorTabEnum.Properties);
        if (selectedEventId) {
          this.store.dispatch(loadEventNotificationsListByEventId({ id: selectedEventId }));
        } else {
          this.store.dispatch(resetEventNotificationsList());
        }
      });
  }

  private handleDelete(name: string, id: number): void {
    this.confirmationService
      .openConfirmDialog('Do you want to remove ' + name + '?')
      .pipe(
        filter(result => !!result),
        take(1),
      )
      .subscribe(() => {
        const projectEvent = this.eventToProjectEventMap()[id];
        this.closeEditorIfEventOpened(projectEvent.id);

        if (this.projectStatus() === Statuses.RevisionInProgress && projectEvent.status === Statuses.Active) {
          const updatedEvent = {
            ...projectEvent,
            status: Statuses.PendingDeletion,
          };

          this.store.dispatch(updateProjectEvent({ projectEvent: updatedEvent }));
        } else {
          this.store.dispatch(deleteProjectEvent({ id: projectEvent.id }));
        }
      });
  }

  private closeEditorIfEventOpened(eventId: number): void {
    if (eventId === this.currentEvent().id) {
      this.closeEditor();
    }
  }

  private handleDuplicateEvent(event: EventGroupModel): void {
    this.duplicateEvent.emit(event);
  }

  private getRightBarWidth(): number {
    return parseInt(getComputedStyle(this.eventsContainer.nativeElement).getPropertyValue('--editor-width'), 10);
  }

  private setRightBarWidth(width: number) {
    const clampedWidth = Math.min(Math.max(width, this.rightBarMinWidth), this.rightBarMaxWidth);

    this.eventsContainer.nativeElement.style.setProperty('--editor-width', `${clampedWidth}px`);
    window.dispatchEvent(new Event('resize'));
  }

  protected readonly EntityType = EntityType;
  protected readonly EventActions = EventActions;
  protected readonly Statuses = Statuses;
}
