import { Injectable } from '@angular/core';
import { AppConfig } from '../core/config/app.config';
import { Observable } from 'rxjs';
import { PharHttp } from '../core/http/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ExportDataService {
  constructor(
    private config: AppConfig,
    private httpClient: PharHttp,
  ) {}

  exportPDF(data, options): Observable<any> {
    return this.httpClient.post(this.config.buildUri('export') + '/ExportPDFNodeService', data, options);
  }
}
