import { Component, input } from '@angular/core';
import { IApprovalStep } from './approval-step.interface';
import { NgClass } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'phar-approval-step',
  templateUrl: 'approval-step.component.html',
  styleUrl: 'approval-step.component.scss',
  standalone: true,
  imports: [NgClass, MatIcon],
})
export class ApprovalStepComponent {
  index = input<number>();
  step = input.required<IApprovalStep>();
}
