/* src/app/shared/approval-step/approval-step.component.scss */
.approval {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d7d7d7;
  border-radius: 7px;
  padding: 0.75rem;
}
.approval_info {
  display: flex;
  align-items: center;
  flex-basis: 20%;
}
.approval_info_badge {
  color: #272931;
  background-color: #f6f6f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  min-width: 2.5rem;
}
.approval_info_label {
  margin: 0 0.75rem;
  font-size: 0.9rem;
  font-weight: bold;
}
.approval_info_active .approval_info_badge {
  color: white;
  background-color: #4f66ab;
}
.approval_info_approved .approval_info_badge {
  color: white;
  background-color: #428f5e;
}
.approval_info_approved .approval_info_label {
  color: #428f5e;
}
.approval_info_rejected .approval_info_badge {
  color: white;
  background-color: #c74940;
}
.approval_body {
  flex-basis: 80%;
}
.approval_action-icon {
  margin-right: 0.5rem;
}
/*# sourceMappingURL=approval-step.component.css.map */
