import { Component, inject, Injector, Input, OnDestroy, OnInit, signal, WritableSignal } from '@angular/core';

import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map, shareReplay, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CdkDropList } from '@angular/cdk/drag-drop';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

import { resetAssignList } from '../store/assign.actions';
import { AppState } from '../../store/models/app.state';
import { AssignModel } from '../assign.model';
import { FormModel } from '../../form/form.model';
import { loadFormListByStatuses } from '../../form/store/form.actions';
import { INPUT_DEBOUNCE_TIME } from '../../core/config/app.constants';
import { toggleSidebarSection, ToggleSidebarSectionState } from '../../animations';
import { PreviewFormQuestionsDialogComponent } from '../../form/preview-form-questions-dialog/preview-form-questions-dialog.component';

import { selectAssignListState } from '../store/assign.state';
import { selectFormStateFormList } from '../../form/store/form.state';
import { BaseComponent } from '../../shared/base.class';
import { DropdownFilter } from '../../shared/list-filter/filter-items/dropdown-filter.class';
import { FormService } from '../../form/form.service';
import { ListFilter } from '../../shared/list-filter/list-filter.interface';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { Statuses } from '../../shared/models/statuses.enum';

@Component({
  selector: 'phar-study-assessments',
  templateUrl: 'study-assessments.component.html',
  styleUrls: ['study-assessments.component.scss'],
  animations: [toggleSidebarSection],
})
export class StudyAssessmentsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() connectedTo: CdkDropList[] = [];
  @Input() listName: string;
  lastReleasedSectionState: ToggleSidebarSectionState = ToggleSidebarSectionState.Open;
  librarySectionState: ToggleSidebarSectionState = ToggleSidebarSectionState.Open;
  assessments$: Observable<AssignModel[]>;
  formService: FormService = inject(FormService);
  injector: Injector = inject(Injector);
  public filteredForms$: Observable<FormModel[]>;
  public readonly lastReleasedFormsCount: number = 10;
  readonly toggleSidebarSectionState = ToggleSidebarSectionState;
  filterByType: WritableSignal<ListFilter | null> = signal<ListFilter | null>(null);
  store: Store<AppState> = inject(Store);
  private releasedForms$: Observable<FormModel[]>;
  isLoading = toSignal(
    this.store.pipe(
      map((state: AppState) => state.ui.globalLoading),
      debounceTime(100),
    ),
    { injector: this.injector },
  );
  private readonly searchFormsBy$ = new BehaviorSubject<string | null>(null);

  constructor(private readonly dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.initObservables();
    this.store.dispatch(loadFormListByStatuses({ statuses: [Statuses.Released] }));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(resetAssignList());
  }

  addFilterByType(): void {
    this.filterByType.set(this.generateFilter());
  }

  handleFilterUpdate(e: { action: 'update' | 'delete'; filter: ListFilter | ListFilter[] }): void {
    if (e.action === 'delete') {
      this.filterByType.set(null);
      return;
    }
    this.filterByType.set(this.generateFilter((e.filter as ListFilter).value));
  }

  onSearchInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.searchFormsBy$.next(inputElement.value);
  }

  toggleLastReleasedSection(): void {
    this.lastReleasedSectionState =
      this.lastReleasedSectionState === ToggleSidebarSectionState.Open
        ? ToggleSidebarSectionState.Close
        : ToggleSidebarSectionState.Open;
  }

  toggleLibrarySection(): void {
    this.librarySectionState =
      this.librarySectionState === ToggleSidebarSectionState.Open
        ? ToggleSidebarSectionState.Close
        : ToggleSidebarSectionState.Open;
  }

  openFormPreviewDialog(form: FormModel): void {
    this.dialog.open(PreviewFormQuestionsDialogComponent, {
      width: '75%',
      maxWidth: '880px',
      height: '90%',
      data: {
        form,
      },
    });
  }

  private initObservables(): void {
    this.assessments$ = this.store.select(selectAssignListState);
    this.releasedForms$ = this.store.select(selectFormStateFormList).pipe(
      map((forms: FormModel[]) => forms.filter(form => form.formStatus === Statuses.Released && !form.isArchived)),
      map((forms: FormModel[]) =>
        forms.sort((a, b) => new Date(b.releaseDate).getTime() - new Date(a.releaseDate).getTime()),
      ),
    );
    this.filteredForms$ = combineLatest([
      this.releasedForms$,
      this.searchFormsBy$.pipe(debounceTime(INPUT_DEBOUNCE_TIME), distinctUntilChanged()),
      toObservable(this.filterByType, { injector: this.injector }),
    ]).pipe(
      map(([forms, searchTerm, filterType]) => {
        if (!searchTerm && !filterType?.value) {
          return forms;
        }

        return forms.filter(form => {
          if (filterType?.value) {
            return (
              form.name.toLowerCase().includes(searchTerm?.toLowerCase() || '') && filterType.value.includes(form.type)
            );
          } else {
            return form.name.toLowerCase().includes(searchTerm.toLowerCase());
          }
        });
      }),
      takeUntil(this.destroy$),
      shareReplay(1),
    );
  }

  private generateFilter(value?: number[]): DropdownFilter {
    return new DropdownFilter('type', value, 'Form type', {
      multiple: true,
      data: this.formService.getFormTypes(),
    });
  }
}
