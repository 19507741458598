import { Injectable } from '@angular/core';
import { PharHttp } from '../core/http/http-client.service';
import { AppConfig } from '../core/config/app.config';
import { Observable } from 'rxjs';
import { ProjectEventModel } from './project-event.model';

@Injectable()
export class EventService {
  constructor(
    private http: PharHttp,
    private config: AppConfig,
  ) {}

  updateProjectEvent(projectEvent: ProjectEventModel) {
    return this.http.put(this.config.buildUri('projectEvent') + '/UpdateProjectEventAndEvent', projectEvent);
  }

  updateProjectEvents(projectEvent: ProjectEventModel[]): Observable<ProjectEventModel[]> {
    return this.http.put(this.config.buildUri('projectEvent') + '/UpdateProjectEventsAndEvents', projectEvent);
  }

  createProjectEvent(projectEvent: ProjectEventModel) {
    const projectEventBody = { ...projectEvent };
    delete projectEventBody.id;
    delete projectEventBody.eventId;
    delete projectEventBody.orderPriority; // TODO: remove when priority requerments are completed
    delete projectEventBody.project;
    delete projectEventBody.event.id;

    return this.http.post(
      this.config.buildUri('projectEvent') + '/CreateProjectEventAndEvent?isAmend=false',
      projectEventBody,
    );
  }

  deleteProjectEvent(id: number): Observable<any> {
    return this.http.delete(this.config.buildUri('projectEvent') + '/ProjectEvent/?projectEventId=' + id);
  }

  getProjectEventListByProjectId(id: number): Observable<ProjectEventModel[]> {
    return this.http.get(this.config.buildUri('projectEvent') + `/GetProjectEventsByProjectId?projectId=${id}`);
  }

  getProjectEventsCommentsCounters(projectId: number): Observable<any[]> {
    return this.http.get(
      this.config.buildUri('projectEventComments') + `/ProjectEventCommentCount?projectId=${projectId}`,
    );
  }
}
