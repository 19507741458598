import { Injectable } from '@angular/core';
import { PharHttp } from '../../core/http/http-client.service';
import { AppConfig } from '../../core/config/app.config';
import { IEventNotificationModel } from './event-notification.model';
import { Observable } from 'rxjs';

@Injectable()
export class EventNotificationsService {
  private readonly baseUrl: string;

  constructor(
    private http: PharHttp,
    private config: AppConfig,
  ) {
    this.baseUrl = `${this.config.buildUri('eventNotifications')}/`;
  }

  getEventNotifications(): Observable<IEventNotificationModel[]> {
    const url = `${this.baseUrl}EventNotifications/`;
    return this.http.get(url);
  }

  getEventNotificationsByEventId(eventId: number): Observable<IEventNotificationModel[]> {
    const url = `${this.baseUrl}GetEventNotificationsByEvent/?eventId=${eventId}`;
    return this.http.get(url);
  }

  create(notification: IEventNotificationModel): Observable<IEventNotificationModel> {
    const url = `${this.baseUrl}EventNotifications/`;
    return this.http.post(url, notification);
  }

  delete(notificationId: number): Observable<any> {
    const url = `${this.baseUrl}EventNotifications?eventId=${notificationId}`;
    return this.http.delete(url);
  }

  update(notification: IEventNotificationModel): Observable<IEventNotificationModel> {
    const url = `${this.baseUrl}EventNotifications/`;
    return this.http.put(url, notification);
  }
}
