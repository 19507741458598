import {
  AfterViewInit,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Injector,
  input,
  OnDestroy,
  OnInit,
  Output,
  Signal,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ProjectEventModel } from '../project-event.model';
import { DraggableListViewConfigModel } from '../../shared/draggable-list-view/draggable-list-view-model';
import { UtilsService } from '../../core/utils.service';
import { select, Store } from '@ngrx/store';
import { AppState, getState } from '../../store/models/app.state';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import {
  changeCurrentProjectEvent,
  deleteProjectEvent,
  loadEventNotificationsListByEventId,
  loadProjectEventListByProjectId,
  loadProjectEventsCommentsState,
  resetCurrentProjectEvent,
  resetEventNotificationsList,
  resetProjectEventList,
  updateEditor,
  updateProjectEvent,
  updateProjectEvents,
} from '../store/event.actions';
import { ElementTypeEnum } from '../enums/element-type.enum';
import { ListViewColumnModel } from '../../shared/list-view/list-view-column.model';
import { EventGroupModel, EventModel } from '../event.model';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { FieldType } from '../../shared/draggable-list-view/models/group-model';
import { EventActions } from '../enums/event-actions.enum';
import { ADHOC_EVENTS_TYPES } from '../../core/config/app.constants';
import { EventsEditorTabEnum } from '../events/events.component';
import {
  selectCurrentEventProjectEvent,
  selectEventsCommentsState,
  selectListEventNotificationsState,
} from '../store/event.state';
import { environment } from '../../../environments/environment';
import { ResizingEvent } from '../../shared/models/resizing-event.interface';
import { MatDialog } from '@angular/material/dialog';
import { EventCommentDialogComponent } from '../event-comments/event-comment.dialog.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { COMMENTS_DIALOG_CONFIG } from '../../shared/entity-comments/entity-comment-base/base-comments-component.config';
import { ProjectService } from '../../project/project.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Statuses } from '../../shared/models/statuses.enum';
import { cloneDeep } from 'lodash-es';
import { ICommentsState } from '../../shared/models/comments-state.interface';
import {
  checkProjectHasBeenRejected,
  selectCurrentProjectManageProject,
  selectCurrentProjectStatusStatus,
} from '../../project/store/project.actions';
import { ProjectModel } from '../../project/project.model';
import { StudyEntityActionStateService } from '../../shared/services/study-entity-action-state.service';

@Component({
  selector: 'phar-scheduled-events-list',
  templateUrl: './scheduled-events-list.component.html',
  styleUrls: ['./scheduled-events-list.component.scss'],
})
export class ScheduledEventsListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('eventNameTemplate', { static: true }) eventNameTemplate: TemplateRef<any>;
  @ViewChild('expandIconTemplate', { static: true }) expandIconTemplate: TemplateRef<any>;
  @ViewChild('startTemplate', { static: true }) startTemplate: TemplateRef<any>;
  @ViewChild('beforeDeviationTemplate', { static: true }) beforeDeviationTemplate: TemplateRef<any>;
  @ViewChild('afterDeviationTemplate', { static: true }) afterDeviationTemplate: TemplateRef<any>;
  @ViewChild('elementTypeTemplate', { static: true }) elementTypeTemplate: TemplateRef<any>;
  @ViewChild('eventTypeTemplate', { static: true }) eventTypeTemplate: TemplateRef<any>;
  @ViewChild('isRepeatableTemplate', { static: true }) isRepeatableTemplate: TemplateRef<any>;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: TemplateRef<any>;
  @ViewChild('statusChangeTemplate', { static: true }) statusChangeTemplate: TemplateRef<any>;
  @ViewChild('toggleAllTemplate', { static: true }) toggleAllTemplate: TemplateRef<any>;
  @ViewChild('commentsTemplate', { static: true }) commentsTemplate: TemplateRef<any>;
  @ViewChild('eventsContainer', { static: true }) eventsContainer: ElementRef;
  editLocked = input(false);
  @Output() duplicateEvent: EventEmitter<EventModel> = new EventEmitter<EventModel>();

  @HostListener('window:mousemove', ['$event'])
  private updateSidebarWidth(event: MouseEvent) {
    if (!this.rightBarResizingEvent.isResizing) {
      return;
    }

    const cursorDeltaX = this.rightBarResizingEvent.startingCursorX - event.clientX;
    const newWidth = this.rightBarResizingEvent.startingWidth + cursorDeltaX;

    this.setRightBarWidth(newWidth);
  }

  @HostListener('window:mouseup')
  private stopSidebarResizing() {
    this.rightBarResizingEvent.isResizing = false;
  }

  editorShown: boolean;
  activeEditorTab: EventsEditorTabEnum = EventsEditorTabEnum.Properties;

  selectedEventId: number;
  baselineEventId: number;
  injector: Injector = inject(Injector);
  store: Store<AppState> = inject(Store);
  noEventsMsg = 'Please click Add above on the right to start creating study events.';
  scheduledEvents = toSignal(
    this.store
      .select(state => state.event.listProjectEvents)
      .pipe(
        map(projectEventList => {
          return projectEventList
            .filter((projectEvent: ProjectEventModel) => projectEvent.event !== null)
            .map((projectEvent: ProjectEventModel) => {
              if (projectEvent.isBaseline) {
                this.baselineEventId = projectEvent.eventId;
              }

              return { ...projectEvent.event };
            });
        }),
        map((events: EventModel[]) =>
          events.filter((event: EventModel) => !ADHOC_EVENTS_TYPES.includes(event.elementType)),
        ),
      ),
    { injector: this.injector },
  );
  project: Signal<ProjectModel> = toSignal(this.store.select(selectCurrentProjectManageProject), {
    injector: this.injector,
  });
  projectStatus = toSignal(this.store.select(selectCurrentProjectStatusStatus), {
    injector: this.injector,
  });
  nextStatus = computed(() => {
    return this.utilsService.getNextStatus(this.project());
  });

  prevStatus = computed(() => {
    if (this.projectHasBeenRejected()) {
      return Statuses.PendingRevision;
    }
    return this.utilsService.getNextStatus(this.project(), true);
  });

  isConfirmAllDisabled = computed(() => {
    return this.studyEntityActionStateService.isBulkStatusChangeLocked(
      this.scheduledEvents()
        .filter(item => item.elementType !== this.ElementType.Baseline)
        .map(item => {
          return this.eventToProjectEventMap()[item.id].status;
        }),
      this.projectStatus(),
      this.nextStatus(),
    );
  });
  isConfirmAllChecked = computed(() => {
    return this.studyEntityActionStateService.isBulkStatusChangeChecked(
      this.scheduledEvents()
        .filter(item => item.elementType !== this.ElementType.Baseline)
        .map(item => {
          return this.eventToProjectEventMap()[item.id].status;
        }),
      this.nextStatus(),
    );
  });
  selectedEvent$: Observable<EventModel>;
  numberOfEventNotifications$: Observable<number>;
  projectService: ProjectService = inject(ProjectService);
  projectEvents = toSignal(
    this.store.select(state => state.event.listProjectEvents),
    { injector: this.injector },
  );
  currentEvent = toSignal(this.store.select(selectCurrentEventProjectEvent), { injector: this.injector });
  projectEventsCommentsCounters = toSignal(this.store.select(selectEventsCommentsState), { injector: this.injector });
  scheduledEventsCommentsState = computed(() => {
    const refinedCommentsStates = {};

    this.scheduledEvents().forEach(event => {
      const projectEvent = this.eventToProjectEventMap()[event.id];
      if (!this.projectEventsCommentsCounters()[projectEvent.id]) {
        return;
      }
      refinedCommentsStates[projectEvent.id] = this.projectEventsCommentsCounters()[projectEvent.id];
    });
    return refinedCommentsStates;
  });
  allCommentsResolved = computed(() => {
    if (!Object.values(this.scheduledEventsCommentsState()).length) {
      return true;
    }
    return Object.values(this.scheduledEventsCommentsState()).every(
      (comments: ICommentsState) => comments.isAllResolved,
    );
  });
  eventToProjectEventMap = computed(() => {
    const eventToProjectEventMapping = {};
    this.projectEvents().forEach(projectEvent => {
      // its needed because we are using projectEvent.id for comments,but in the actions template we are receiving event.id
      eventToProjectEventMapping[projectEvent.eventId] = projectEvent;
    });
    return eventToProjectEventMapping;
  });
  projectHasBeenRejected = toSignal(this.store.select(checkProjectHasBeenRejected), {
    injector: this.injector,
  });
  config: DraggableListViewConfigModel = {
    columns: [
      {
        field: 'id',
        size: '0',
        title: 'ID',
        show: false,
        hasTemplate: false,
        template: null,
      },
      {
        field: 'expand',
        size: '40px',
        title: '',
        show: true,
        hasTemplate: true,
        template: null,
      },
      {
        field: 'eventName',
        title: 'Event Name',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'start',
        title: 'Start',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'beforeDeviation',
        title: 'Window Before',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'afterDeviation',
        title: 'Window After',
        size: '1fr',
        show: true,
        order: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'elementType',
        title: 'Event Type',
        size: '1fr',
        show: true,
        order: false,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'isRepeatable',
        title: 'Recurring',
        size: '70px',
        // Add as many pixels as left margin added for nested columns (2rem).
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'userIdUpdated', // @TODO change this to status column when its done
        title: 'Status',
        size: '120px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
      },
      {
        field: 'orderPriority', // used for change status placeholder
        title: 'Change status',
        size: '100px',
        show: true,
        hasTemplate: true,
        selectable: true,
        template: null,
        hasHeaderCellTemplate: true,
      },
      {
        field: 'projectEventGuid', // field is used only for placeholder
        title: 'Comments',
        size: '80px',
        show: true,
        hasTemplate: true,
        template: null,
      },
      {
        field: 'userIdCreated', // field is used only for placeholder
        title: 'Actions',
        size: '50px',
        // Add as many pixels as left margin added for nested columns (2rem).
        // sizeNested: '86px',
        show: true,
        hasTemplate: true,
        template: null,
      },
    ],
    actions: [],
    customClasses: ['events-list'],
  };

  eventActionsFnMap = {
    [EventActions.Edit]: this.handleEditEvent,
    [EventActions.Delete]: this.handleDeleteEvent,
    [EventActions.Duplicate]: this.handleDuplicateEvent,
    [EventActions.Comments]: this.openCommentsDialog,
  };

  rightBarResizingEvent: ResizingEvent = {
    isResizing: false,
    startingCursorX: 0,
    startingWidth: 0,
  };

  readonly ElementType = ElementTypeEnum;
  readonly FieldType = FieldType;
  readonly EventsEditorTabEnum = EventsEditorTabEnum;
  private readonly rightBarMinWidth = 270;
  private readonly rightBarMaxWidth = window.innerWidth / 2;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private utilsService: UtilsService,
    private confirmationService: PharConfirmDialogService,
    private dialog: MatDialog,
    private studyEntityActionStateService: StudyEntityActionStateService,
  ) {}

  ngOnInit(): void {
    this.selectedEventId = getState(this.store).event.current.projectEvent.event.id;

    this.store
      .pipe(
        select(state => state.project.current.project.id),
        filter(id => !!id),
        takeUntil(this.destroy$),
      )
      .subscribe(id => {
        this.store.dispatch(loadProjectEventListByProjectId({ id: id }));
      });

    this.selectedEvent$ = this.store.select(selectCurrentEventProjectEvent);
    this.numberOfEventNotifications$ = this.store
      .select(selectListEventNotificationsState)
      .pipe(map(eventNotifications => eventNotifications.length));

    this.loadEventNotificationsOnSelectEvent();
  }

  ngAfterViewInit(): void {
    const templates = {
      expand: this.expandIconTemplate,
      eventType: this.eventTypeTemplate,
      elementType: this.elementTypeTemplate,
      afterDeviation: this.afterDeviationTemplate,
      beforeDeviation: this.beforeDeviationTemplate,
      start: this.startTemplate,
      isRepeatable: this.isRepeatableTemplate,
      userIdUpdated: this.statusTemplate,
      orderPriority: this.statusChangeTemplate,
      'orderPriority@header': this.toggleAllTemplate,
      projectEventGuid: this.commentsTemplate,
      userIdCreated: this.actionsTemplate, //userIdCreated is user as a placeholder only
      eventName: this.eventNameTemplate,
    };

    /*if (this.editLocked()) {
      delete templates.userIdCreated;
      this.config.columns = this.config.columns.filter(item => item.field !== 'userIdCreated');
    }*/

    this.config.columns = this.utilsService.setColumnTemplate<ListViewColumnModel>(
      this.config.columns,
      templates,
      'field',
    );
    if (this.selectedEventId) {
      this.scrollToElement(this.selectedEventId);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.store.dispatch(resetProjectEventList());
    this.store.dispatch(resetCurrentProjectEvent());
  }

  columnsSelectionChange(columns: ListViewColumnModel[]): void {
    this.config.columns = columns;
  }

  startRightBarResizing(event: MouseEvent): void {
    this.rightBarResizingEvent = {
      isResizing: true,
      startingCursorX: event.clientX,
      startingWidth: this.getRightBarWidth(),
    };
  }

  actionHandler($event: { eventName: EventActions; dataItem: EventGroupModel }): void {
    const { eventName, dataItem } = $event;
    try {
      this.eventActionsFnMap[eventName].bind(this)(dataItem);
    } catch (e) {
      console.error(`Method not implemented ${eventName}`, e);
    }
  }

  handleEditEvent(dataItem: EventGroupModel): void {
    this.store.dispatch(changeCurrentProjectEvent({ eventId: dataItem.id }));
    this.selectedEventId = dataItem.id;
    this.editorShown = true;
    this.store.dispatch(updateEditor());
  }

  handleDeleteEvent(dataItem: EventGroupModel): void {
    this.confirmationService
      .openConfirmDialog('Do you want to remove ' + dataItem.eventName + '?')
      .pipe(
        filter(result => !!result),
        take(1),
      )
      .subscribe(() => {
        const projectEvent = this.eventToProjectEventMap()[dataItem.id];
        // check if the item that you are about to delete is selected in the editor;
        this.closeEditorIfEventOpened(projectEvent.id);

        if (this.projectStatus() === Statuses.RevisionInProgress && projectEvent.status === Statuses.Active) {
          const updatedEvent = {
            ...projectEvent,
            status: Statuses.PendingDeletion,
          };

          this.store.dispatch(updateProjectEvent({ projectEvent: updatedEvent }));
        } else {
          this.store.dispatch(deleteProjectEvent({ id: projectEvent.id }));
        }
      });
  }

  handleDuplicateEvent(event: EventGroupModel): void {
    this.duplicateEvent.emit(event);
  }

  openCommentsDialog(event: EventModel): void {
    const projectEvent = this.projectEvents().find(item => item.eventId === event.id);
    if (!projectEvent) {
      return;
    }
    this.dialog
      .open(EventCommentDialogComponent, {
        data: {
          projectEventId: projectEvent.id,
        },
        ...COMMENTS_DIALOG_CONFIG,
      })
      .afterClosed()
      .pipe(
        filter(shouldRefreshCounters => !!shouldRefreshCounters),
        take(1),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(loadProjectEventsCommentsState({ projectId: this.project().id }));
          // if (projectEvent.status === this.nextStatus()) {
          //   this.store.dispatch(updateProjectEvent({ projectEvent: { ...projectEvent, status: this.prevStatus() } }));
          // }
        },
      });
  }

  handleAfterUpdate({ update }: { update: boolean }) {
    if (!update) {
      this.addEvent(false);
    }
  }

  addEvent(reset = true): void {
    if (reset) {
      this.store.dispatch(resetCurrentProjectEvent());
    }
    const selectedEvent = getState(this.store).event.current.projectEvent.event;
    this.editorShown = true;
    this.store.dispatch(updateEditor());
    this.selectedEventId = selectedEvent.id;
    this.scrollToElement(this.selectedEventId);
  }

  selectEvent(event: EventModel): void {
    const projectEvent = this.eventToProjectEventMap()[event.id];

    if (
      this.editLocked() ||
      (this.projectHasBeenRejected() && projectEvent.status === Statuses.Approved) ||
      projectEvent.status === this.nextStatus()
    ) {
      return;
    }

    if (event.elementType === this.ElementType.Baseline || event.id === this.selectedEventId) {
      this.selectedEventId = null;
      this.editorShown = false;
      this.scrollToElement(event.id);
      return;
    }

    this.store.dispatch(changeCurrentProjectEvent({ eventId: event.id }));
    this.selectedEventId = event.id;
    this.editorShown = true;
    this.store.dispatch(updateEditor());
    this.scrollToElement(event.id);
  }

  handleScrollEnd() {
    if (!this.selectedEventId) {
      return;
    }
    this.scrollToElement(this.selectedEventId, true, 'preview-item-');
  }

  scrollToElement(elementId: number, scrollIntoPreviewContainer = false, prefix = 'item-'): void {
    if (!elementId) {
      return;
    }
    // try {
    //   this.utilsService.waitForElementToExist(`#${prefix}${elementId}`).then((element: HTMLElement) => {
    //     if (scrollIntoPreviewContainer) {
    //       const container = document.getElementById('preview-container');
    //       if (!container) {
    //         return;
    //       }
    //       container.scrollLeft = element.offsetLeft - element.clientWidth * 3;
    //       return;
    //     }
    //
    //     (element as HTMLElement).scrollIntoView({ behavior: 'smooth' });
    //   });
    // } catch (e) {
    //   if (!environment.production) {
    //     console.warn(e);
    //   }
    // }
  }

  closeEditor() {
    this.editorShown = false;
    this.selectedEventId = null;
    this.activeEditorTab = EventsEditorTabEnum.Properties;
    this.store.dispatch(resetCurrentProjectEvent());
  }

  setActiveEditorTab(tab: EventsEditorTabEnum): void {
    this.activeEditorTab = tab;
  }

  handleBulkStatusChange(el: MatSlideToggle): void {
    if (!el.checked) {
      el.checked = true;
      return;
    }
    // if (!this.allCommentsResolved()) {
    //   this.confirmationService
    //     .openConfirmDialog('All events comments should be resolved', 'Warning', 'Ok', '')
    //     .pipe(take(1))
    //     .subscribe();
    //   el.checked = false;
    //
    //   return;
    // }

    const scheduledProjectEvents: ProjectEventModel[] = this.scheduledEvents()
      .filter(el => el.elementType !== this.ElementType.Baseline)
      .map(projectEvent => this.eventToProjectEventMap()[projectEvent.id])
      .filter(
        ({ status }) => !this.studyEntityActionStateService.isEntityChangeStatusChecked(status, this.nextStatus()),
      );

    const message = `You are about to move <b>${scheduledProjectEvents.length}</b> records in status <b>
      ${this.utilsService.getFormattedStatus(this.nextStatus())}</b>. Are you sure?`;
    this.confirmationService
      .openConfirmDialog(message, 'Please confirm', 'Ok', 'Cancel', true, 300, true)
      .pipe(
        take(1),
        map(result => !!result),
        tap(result => {
          if (!result) {
            el.checked = false;
          }
        }),
        filter(canChangeStatuses => canChangeStatuses),
      )
      .subscribe({
        next: () => {
          //TODO: delete pending deletion events. Confirm button is disabled if there are pending deletion now
          const updatedProjectEvents = cloneDeep(scheduledProjectEvents).map(projectEvent => ({
            ...projectEvent,
            status: this.nextStatus(),
          }));
          this.store.dispatch(updateProjectEvents({ projectEvents: updatedProjectEvents }));
        },
      });
  }

  handleChangeStatus(element: EventModel, el: MatSlideToggle): void {
    const projectEvent: ProjectEventModel = this.eventToProjectEventMap()[element.id];
    if (!el.checked || !projectEvent) {
      el.checked = !el.checked;
      return;
    }

    // const itemMeta = this.scheduledEventsCommentsState()[projectEvent.id];
    // if (itemMeta && !itemMeta.isAllResolved) {
    //   this.confirmationService
    //     .openConfirmDialog('All comments should be resolved', 'Warning', 'Ok', '')
    //     .pipe(take(1))
    //     .subscribe();
    //   el.checked = false;
    //
    //   return;
    // }

    if (projectEvent.status === Statuses.PendingDeletion) {
      this.confirmationService
        .openConfirmDialog('Do you want to remove ' + projectEvent.event.eventName + '?')
        .pipe(
          take(1),
          filter(isConfirmed => isConfirmed),
        )
        .subscribe(() => {
          this.store.dispatch(deleteProjectEvent({ id: projectEvent.id }));
        });
    } else {
      this.store.dispatch(updateProjectEvent({ projectEvent: { ...projectEvent, status: this.nextStatus() } }));
    }

    this.closeEditorIfEventOpened(projectEvent.id);
  }

  private closeEditorIfEventOpened(eventId: number): void {
    if (eventId === this.currentEvent().id) {
      this.store.dispatch(resetCurrentProjectEvent());
      this.editorShown = false;
      this.selectedEventId = null;
      this.store.dispatch(updateEditor());
    }
  }

  private loadEventNotificationsOnSelectEvent(): void {
    this.selectedEvent$
      .pipe(
        map(selectedEvent => selectedEvent.id),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(selectedEventId => {
        this.setActiveEditorTab(EventsEditorTabEnum.Properties);
        if (selectedEventId) {
          this.store.dispatch(loadEventNotificationsListByEventId({ id: selectedEventId }));
        } else {
          this.store.dispatch(resetEventNotificationsList());
        }
      });
  }

  private getRightBarWidth(): number {
    return parseInt(getComputedStyle(this.eventsContainer.nativeElement).getPropertyValue('--rightBarWidth'), 10);
  }

  private setRightBarWidth(width: number) {
    const clampedWidth = Math.min(Math.max(width, this.rightBarMinWidth), this.rightBarMaxWidth);

    this.eventsContainer.nativeElement.style.setProperty('--rightBarWidth', `${clampedWidth}px`);
    window.dispatchEvent(new Event('resize'));
  }

  protected readonly EventActions = EventActions;
  protected readonly Statuses = Statuses;
}
