import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
  signal,
  Signal,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { startWith, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { IEventNotificationModel } from '../event-notification.model';
import { EventNotificationsService } from '../event-notifications.service';
import { DropdownModel } from '../../../shared/models/dropdown-model';
import { DateTimeUnitEnum } from '../../enums/datetime-unit.enum';
import { AppState } from '../../../store/models/app.state';
import { loadEventNotificationsListByEventId } from '../../store/event.actions';
import { FormControls } from '../../../shared/types/form-controls.type';
import { UserRoleEnum } from '../../../user/user-roles.enum';
import { TimingOptions } from '../../../shared/models/timing-options.enum';
import { toSignal } from '@angular/core/rxjs-interop';

type NotificationFormGroupValue = Omit<
  IEventNotificationModel,
  'eventId' | 'id' | 'shouldNotifyResponsePerson' | 'userIds'
>;
type NotificationFormGroup = FormControls<NotificationFormGroupValue>;

@Component({
  selector: 'phar-event-notification',
  templateUrl: 'event-notification.component.html',
  styleUrls: ['event-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventNotificationComponent implements OnInit {
  form: FormGroup<NotificationFormGroup>;
  notification = signal<IEventNotificationModel>(null);
  isOpenState = false;
  injector: Injector = inject(Injector);

  isNewNotification = true;
  formValue: Signal<NotificationFormGroupValue>;
  timingControlValue: Signal<TimingOptions | null>;
  hasPendingChanges: Signal<boolean>;

  readonly userRoles: UserRoleEnum[] = [UserRoleEnum.Coordinator, UserRoleEnum.Investigator, UserRoleEnum.Participant];
  readonly userRolesMap: Record<UserRoleEnum, string> = {
    [UserRoleEnum.Coordinator]: 'Coordinator',
    [UserRoleEnum.Investigator]: 'Investigator',
    [UserRoleEnum.Participant]: 'Participant',
  };
  readonly timingOptions = [
    {
      label: 'Before',
      value: TimingOptions.Before,
    },
    {
      label: 'During',
      value: TimingOptions.During,
    },
    {
      label: 'After',
      value: TimingOptions.After,
    },
  ];
  readonly timeUnitDisplayOptions: DropdownModel[] = [
    {
      label: 'day(s)',
      value: DateTimeUnitEnum.Day,
    },
    {
      label: 'week(s)',
      value: DateTimeUnitEnum.Week,
    },
    {
      label: 'month(s)',
      value: DateTimeUnitEnum.Month,
    },
    // {
    //   label: 'year(s)',
    //   value: DateTimeUnitEnum.Year, @ТОДО This option is current not available in the other system so it should stay commented for now
    // }
  ];
  private _disabled = false;
  @Output() createNewNotification = new EventEmitter<IEventNotificationModel>();
  @Output() deleteNewNotification = new EventEmitter();

  constructor(
    private readonly eventNotificationsService: EventNotificationsService,
    private readonly store: Store<AppState>,
  ) {}

  @Input({ required: true })
  set eventNotification(notification: IEventNotificationModel) {
    const roleTypesNotification: IEventNotificationModel = {
      ...notification,
      roleTypes: notification.roleTypes || [UserRoleEnum.Participant],
    };

    this.notification.set(roleTypesNotification);
    this.isNewNotification = typeof this.notification().id !== 'number';
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;

    if (!this.form) {
      return;
    }

    if (this.disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  ngOnInit() {
    this.form = this.getInitialFormGroup();
    this.form.patchValue(this.notification());
    this.formValue = toSignal(this.form.valueChanges.pipe(startWith(this.form.getRawValue())), {
      injector: this.injector,
    }) as Signal<NotificationFormGroupValue>;
    const timingControl = this.form.controls.timing;
    this.timingControlValue = toSignal(timingControl.valueChanges.pipe(startWith(timingControl.value)), {
      injector: this.injector,
    });
    this.hasPendingChanges = computed(() => {
      return !isEqual(
        {
          ...this.notification(),
          roleTypes: this.notification().roleTypes?.sort(),
        },
        {
          ...this.notification(),
          ...this.formValue(),
          roleTypes: this.formValue().roleTypes?.sort(),
        },
      );
    });

    if (this.form.invalid) {
      this.isOpenState = true;
    }

    if (this.disabled) {
      this.form.disable();
    }

    effect(
      () => {
        this.handleTimingChange(this.timingControlValue());
      },
      { injector: this.injector },
    );
  }

  saveNotification(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const notification: IEventNotificationModel = {
      ...this.notification(),
      ...this.form.getRawValue(),
    };

    if (this.isNewNotification) {
      this.createNewNotification.emit(notification);
      return;
    }

    this.eventNotificationsService
      .update(notification)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.store.dispatch(loadEventNotificationsListByEventId({ id: notification.eventId }));
        },
      });
  }

  deleteNotification(): void {
    if (this.isNewNotification) {
      this.deleteNewNotification.emit();
      return;
    }

    this.eventNotificationsService
      .delete(this.notification().id)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.store.dispatch(loadEventNotificationsListByEventId({ id: this.notification().eventId }));
        },
      });
  }

  toggleOpenCloseState(): void {
    this.isOpenState = !this.isOpenState;
  }

  discardChanges(): void {
    this.form.patchValue(this.notification());
  }

  private getInitialFormGroup(): FormGroup<NotificationFormGroup> {
    return new FormGroup<NotificationFormGroup>({
      timing: new FormControl(null, [Validators.required]),
      number: new FormControl(null, [Validators.required]),
      unit: new FormControl(null, [Validators.required]),
      specificTime: new FormControl(null, [Validators.required]),
      customMessage: new FormControl(null, [Validators.required]),
      roleTypes: new FormControl([UserRoleEnum.Participant], [Validators.required]),
    });
  }

  private handleTimingChange(value: TimingOptions | null): void {
    const { number, unit } = this.form.controls;

    if (value === TimingOptions.During) {
      this.resetControls(number, unit);
    } else {
      this.enableControls(number, unit);
    }
  }

  private resetControls(...controls: FormControl[]): void {
    controls.forEach((control: FormControl) => {
      control.setValue(null);
      control.clearValidators();
      control.updateValueAndValidity();
    });
  }

  private enableControls(...controls: FormControl[]): void {
    controls.forEach((control: FormControl) => {
      control.setValidators([Validators.required]);
      control.updateValueAndValidity();
    });
  }

  protected readonly TimingOptions = TimingOptions;
}
