<div class="d-flex align-items-center">
  @switch (elementType) {
    @case (ElementType.AdverseEvent) {
      <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="calendar" />
      <span>Adverse Event</span>
    }
    @case (ElementType.SevereAdverseEvent) {
      <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="alert" />
      <span>Severe Adverse Event</span>
    }
    @case (ElementType.Withdraw) {
      <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="flag" />
      <span>Withdraw</span>
    }
    @case (ElementType.Baseline) {
      <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="play" />
      <span>Baseline</span>
    }
    @case (ElementType.ScheduledEvent) {
      <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="calendar" />
      <span>Scheduled Event</span>
    }
    @case (ElementType.Group) {
      <mat-icon class="form-template_left-block_form-icon me-2" svgIcon="folder" />
      <span>Group</span>
    }
  }
</div>
