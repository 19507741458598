import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EMPTY } from 'rxjs';
import { IEventEditorData } from './event-editor-data.interface';
import { DropdownModel } from '../../../shared/models/dropdown-model';
import { ElementTypeEnum } from '../../enums/element-type.enum';
import { EventModel } from '../../event.model';
import { startWith, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '../../../shared/base.class';
import { AppState } from '../../../store/models/app.state';
import { Store } from '@ngrx/store';
import { selectListProjectEventsState } from '../../store/event.state';
import { uniqueEventNameValidator } from '../../unique-event-name.validator';

@Component({
  selector: 'phar-adhoc-event-editor-form',
  templateUrl: 'adhoc-event-editor-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdhocEventEditorFormComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  @Input() event: EventModel;
  @Output() formDataChanged: EventEmitter<IEventEditorData> = new EventEmitter<IEventEditorData>();
  form: UntypedFormGroup;
  eventTypes: DropdownModel[] = [
    { label: 'Adverse Event', value: ElementTypeEnum.AdverseEvent },
    { label: 'Severe Adverse Event', value: ElementTypeEnum.SevereAdverseEvent },
    { label: 'Withdraw', value: ElementTypeEnum.Withdraw },
  ];
  DEFAULT_FORM_VALUES = {
    eventName: '',
    elementType: this.eventTypes[0].value,
  };
  private _disabled = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
  ) {
    super();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(isDisabled: boolean) {
    this._disabled = isDisabled;

    if (!this.form) {
      return;
    }

    if (this.disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  ngOnInit() {
    this.initForm();
    this.setFormValues();
    this.addSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.prototype.hasOwnProperty.call(changes, 'event') && !changes.event.isFirstChange()) {
      if (changes.event.currentValue.eventName && changes.event.currentValue.elementType) {
        this.setFormValues();
      } else {
        this.setInitialValues();
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  private addSubscriptions(): void {
    this.form.valueChanges.pipe(startWith(EMPTY), takeUntil(this.destroy$)).subscribe({
      next: (values: { eventName: string; elementType: number }) => {
        this.formDataChanged.emit({ isValid: this.form.valid, values });
      },
    });
  }

  private setFormValues(): void {
    if (!this.event.id) {
      return;
    }
    this.form.setValue({
      eventName: this.event.eventName,
      elementType: this.event.elementType,
    });
  }

  private setInitialValues() {
    this.form.setValue({
      eventName: this.DEFAULT_FORM_VALUES.eventName,
      elementType: this.DEFAULT_FORM_VALUES.elementType,
    });
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      eventName: [
        this.DEFAULT_FORM_VALUES.eventName,
        [Validators.required, Validators.minLength(2)],
        [uniqueEventNameValidator(this.store.select(selectListProjectEventsState), this.event)],
      ],
      elementType: [this.DEFAULT_FORM_VALUES.elementType, Validators.required],
    });

    if (this.disabled) {
      this.form.disable();
    }
  }
}
