/* src/app/events/events/events.component.scss */
#events-switcher button {
  letter-spacing: normal;
  padding: 0 16px;
}
#events ::ng-deep .events-editor-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  z-index: 10;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  border: 1px solid #babbbd;
  background-color: white;
  left: 0;
  transform: translateX(-50%);
}
#events ::ng-deep .events-editor-close-icon mat-icon {
  overflow: unset;
}
/*# sourceMappingURL=events.component.css.map */
