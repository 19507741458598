<button class="close-icon" mat-icon-button (click)="close()">
  <mat-icon class="button-block_small-icon" svgIcon="close" />
</button>

<h1 class="text-center" mat-dialog-title>Select the form you want to replace the current one with</h1>

<div mat-dialog-content>
  <mat-form-field class="mt-3 w-100">
    <phar-autocompleter
      placeholder="Select higher version of the form"
      noDataText="No higher versions of this form"
      [formControl]="selectedFormControl"
      [autoCompleter]="true"
      [data]="data.forms"
      [displayValue]="'name'"
      [filterValue]="'name'"
      [returnValue]="'id'"
      [autoCompleterMinLength]="1">
    </phar-autocompleter>
  </mat-form-field>
</div>

<div align="center" mat-dialog-actions>
  <phar-button [type]="'stroked'" (btnClicked)="close()"> Cancel </phar-button>

  <phar-button class="ms-2" [disabled]="!selectedForm()" [state]="'success'" (btnClicked)="replace()">
    <mat-icon [svgIcon]="'check'" />
    Replace
  </phar-button>
</div>
