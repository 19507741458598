/* src/app/events/adhoc-events-list/adhoc-events-list.component.scss */
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move !important;
}
.full-width,
.three-dots-no-wrap {
  width: 100%;
}
.three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-dots-no-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.white-space-normal {
  white-space: normal;
}
.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.position-fixed {
  position: fixed;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.full-height {
  height: 100vh;
}
.full-height.fit {
  height: calc(100vh - 50px - 50px);
}
.max-height-100 {
  max-height: 100%;
}
.max-height-50 {
  max-height: 50%;
}
.max-width-100 {
  max-width: 100%;
}
.max-width-50 {
  max-width: 50%;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.max-width {
  max-width: 780px;
}
.background-transparent {
  background: transparent !important;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
.text-underline {
  text-decoration: underline;
}
.no-pointer-events {
  pointer-events: none;
}
.hidden {
  display: none;
}
.h-auto {
  height: auto;
}
.h-100 {
  height: 100%;
}
.t-0 {
  top: 0;
}
.b-0 {
  bottom: 0;
}
.l-0 {
  left: 0;
}
.r-0 {
  right: 0;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-end {
  align-items: flex-end;
}
.flex-grow {
  flex-grow: 1;
}
.inline-block {
  display: inline-block;
}
.small-icon {
  width: 1rem !important;
  min-width: 1rem !important;
  height: 1rem !important;
}
button.reverse-icon {
  display: flex;
  flex-direction: row;
}
button.reverse-icon mat-icon {
  margin: 0 0 0 8px !important;
}
.text-strike-out {
  text-decoration: line-through;
}
#adhoc-container {
  --main-container-width: 1fr;
  --editor-width: 0;
  display: grid;
  grid-template-areas: "container editor";
  grid-template-columns: var(--main-container-width) var(--editor-width);
  height: 100%;
}
#adhoc-container.editor-opened {
  --editor-width: 325px;
}
#adhoc-container #adhoc-list {
  grid-area: container;
}
#adhoc-container #adhoc-editor {
  grid-area: editor;
}
#adhoc-container .resize-handle {
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: ew-resize;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.2s ease-out;
}
#adhoc-container .resize-handle:hover {
  width: 3px;
  background-color: #4f66ab;
}
#adhoc-container .resize-handle:hover::after {
  content: "";
  display: block;
  height: 100%;
  width: 24px;
  position: absolute;
  right: -12px;
  z-index: 1;
}
#adhoc-container .resize-handle.resizing::after {
  width: 100px;
  right: -50px;
}
/*# sourceMappingURL=adhoc-events-list.component.css.map */
