/* src/app/events/scheduled-events-editor/scheduled-event-editor.component.scss */
.mat-mdc-slide-toggle.mat-disabled {
  opacity: 1 !important;
}
#editor-actions {
  position: sticky;
  bottom: 0;
  background: #ffffff;
  z-index: 9999999;
  border-top: 1px solid #babbbd !important;
}
form#schedule-editor-form {
  overflow-y: auto;
  height: calc(100% - 64px);
}
.element-type-icon {
  width: 20px;
}
/*# sourceMappingURL=scheduled-event-editor.component.css.map */
