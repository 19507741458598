<phar-list-template #listTemplate>
  <div listHeader>
    <div class="d-flex flex-row justify-content-between mt-2 px-4">
      <button id="add-study" class="ms-auto" mat-stroked-button (click)="syncStudies()">
        <mat-icon svgIcon="refresh" />
        Synchronize
      </button>
    </div>
    <div class="d-flex flex-row justify-content-between mt-4 mb-2 ms-3">
      <phar-list-filter
        [searchField]="'name'"
        [activeFilters]="lisFilterManager.filters$ | async"
        [displayedFilterOptions]="filterableFields"
        [resultCounter]="(listView$ | async).length"
        (filterAdd)="handleFilterChange($event)"
        (filterUpdate)="handleFilterUpdate($event)" />
      <div class="d-flex flex-row justify-content-between ps-3 pe-2">
        <phar-card-list-switcher
          [view]="view"
          [ngClass]="{ 'me-3': view !== ListCardView.List }"
          (afterViewSelected)="changeView($event)" />
        <phar-list-column-selection
          class="ms-1"
          [class.d-none]="view !== ListCardView.List"
          [columns]="config.columns"
          (columnsSelectionChange)="columnsSelectionChange($event)" />
      </div>
    </div>
  </div>

  <div class="list-page-cards" listBody [class.scroll-holder--no-header]="!!!(header | async)">
    @if (view === ListCardView.Card) {
      <phar-cards-list
        uniqueIdentifier="id"
        [cardsType]="'project'"
        [moreOptions]="cardActionsTemplate"
        [cards]="cardsView$ | async | orderBy: (orderPredicate$ | async)"
        [noDataMessage]="(lisFilterManager.filters$ | async).length ? noFilteredResultsMsg : noItemsMsg" />
    }

    @if (view === ListCardView.List) {
      <div class="px-3">
        @defer {
          <phar-table-list-view
            [noDataMessage]="(lisFilterManager.filters$ | async).length ? noFilteredResultsMsg : noItemsMsg"
            [config]="config"
            [loading]="isLoading()"
            [dataSource]="listView$ | async | orderBy: (orderPredicate$ | async)"
            [parentHeight]="listTemplate.listBody.nativeElement.clientHeight"
            (rowClicked)="clickHandler($event)" />
        } @placeholder {
          <div class="w-100 text-center mt-5">Data is loading...</div>
        }
      </div>
    }
  </div>
</phar-list-template>

<ng-template #releaseDateTemplate let-element>
  {{ element.releaseDate | date: 'd MMM y' }}
  @if (!element.releaseDate) {
    <span class="fw-normal">-</span>
  }
</ng-template>

<ng-template #projectNameTemplate let-element>
  <div
    #projectName
    class="text-truncate"
    [pharTooltip]="element.name"
    [pharTooltipDisabled]="projectName.scrollWidth <= projectName.clientWidth">
    {{ element.name }}
  </div>
</ng-template>

<ng-template #createdAtTemplate let-element>
  {{ element.createdAt | date: 'd MMM y' }}
</ng-template>

<ng-template #createdByTemplate let-element>
  <div class="d-flex flex-column w-100">
    @if (element.userIdCreated | pharUserById: users(); as user) {
      <div class="text-truncate user-info fw-normal" [pharTooltip]="user" [pharTooltipDisabled]="user === 'N/A'">
        {{ user }}
      </div>
    } @else {
      -
    }
  </div>
</ng-template>

<ng-template #updatedAtTemplate let-element>
  {{ element.updatedAt | date: 'd MMM y' }}
</ng-template>

<ng-template #updatedByTemplate let-element>
  <div class="d-flex flex-column w-100">
    @if (element.userIdUpdated | pharUserById: users(); as user) {
      <div class="text-truncate user-info fw-normal" [pharTooltip]="user" [pharTooltipDisabled]="user === 'N/A'">
        {{ user }}
      </div>
    } @else {
      -
    }
  </div>
</ng-template>

<ng-template #projectStatusTemplate let-element>
  <phar-status-badge [status]="element.status?.status" [type]="EntityType.Project" />
</ng-template>
<ng-template #projectVersionTemplate let-element>
  <!--  number: '0.1' ?? 'N/A'-->
  {{ element.projectVersion ?? 'N/A' }}
</ng-template>

<ng-template #cardActionsTemplate let-card>
  <ng-template [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ $implicit: card.original }" />
</ng-template>

<ng-template #actionsTemplate let-element>
  <mat-icon
    class="more cursor-pointer rounded-5"
    matRipple
    [matMenuTriggerFor]="actionsMenu"
    (click)="$event.stopImmediatePropagation()"
    >more_vert</mat-icon
  >

  <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
    <button
      class="button-with-icon"
      mat-menu-item
      (click)="actionHandler({ eventName: ProjectActionsEnum.Edit, dataItem: element })">
      <mat-icon svgIcon="edit" />
      <span>Edit</span>
    </button>

    <button
      class="button-with-icon"
      mat-menu-item
      (click)="actionHandler({ eventName: ProjectActionsEnum.Duplicate, dataItem: element })">
      <mat-icon svgIcon="duplicate" />
      <span>Duplicate</span>
    </button>

    @if (element.status?.status === Statuses.Released) {
      <button
        class="button-with-icon"
        mat-menu-item
        (click)="actionHandler({ eventName: ProjectActionsEnum.Amend, dataItem: element })">
        <mat-icon svgIcon="change" />
        <span>Amend</span>
      </button>
    }

    <button
      class="button-with-icon"
      mat-menu-item
      (click)="actionHandler({ eventName: ProjectActionsEnum.Delete, dataItem: element })">
      <mat-icon svgIcon="trash" />
      <span>Delete</span>
    </button>
  </mat-menu>
</ng-template>
<ng-template #cardSubtitleTemplate let-element>
  <div class="mt-2">
    Created: {{ element.createdAt | date: 'd MMM y' }} -
    @if (element.userIdCreated | pharUserById: users(); as user) {
      <span [pharTooltip]="user" [pharTooltipDisabled]="user === 'N/A'">{{ user | shorten: 17 : '...' }} </span>
    }
    @if (element.status?.status === Statuses.Released) {
      <div>Released: {{ element.releaseDate | date: 'd MMM y' }}</div>
    } @else {
      <div>
        Modified: {{ element.updatedAt | date: 'd MMM y' }} -
        @if (element.userIdUpdated | pharUserById: users(); as user) {
          <span [pharTooltip]="user" [pharTooltipDisabled]="user === 'N/A'">{{ user | shorten: 17 : '...' }} </span>
        }
      </div>
    }
  </div>
</ng-template>

<ng-template #releaseDateTemplate let-element>
  {{ element.releaseDate | date: 'd MMM y' }}
</ng-template>

<ng-template #studyIdTemplate let-element>
  {{ element.id }}
</ng-template>

<ng-template #sponsorTemplate let-element>{{ element.sponsorName || '-' }} </ng-template>
