import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementTypeEnum } from '../enums/element-type.enum';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'phar-event-type',
  templateUrl: 'event-type.component.html',
  styleUrls: ['event-type.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule],
})
export class EventTypeComponent {
  @Input() elementType: ElementTypeEnum;
  protected readonly ElementType = ElementTypeEnum;
}
