<!-- <mat-toolbar class="page-toolbar">
  <div class="mfm-second-header_left">
    <button *ngIf="hideSecondLevelMenu" mat-icon-button class="" [routerLink]="[currentRoute]">
      <mat-icon>keyboard_arrow_left</mat-icon> Back to study
    </button>

    <mfm-context-header></mfm-context-header>
  </div>
  <div class="mfm-second-header_center">
    <mfm-third-level-menu></mfm-third-level-menu>
  </div>
  <div class="mfm-second-header_right"></div>
</mat-toolbar> -->
@if (navigateBackLinkInfo$ | async; as navigateBackLinkInfo) {
  <a class="phar-back-link" [routerLink]="navigateBackLinkInfo.routerLink">
    <mat-icon svgIcon="arrow-left" />
    {{ navigateBackLinkInfo.title }}
  </a>
}
<div class="d-flex flex-column">
  @if (!hideSecondLevelMenu) {
    <phar-second-level-menu [links]="secondLevelLinks">
      @if (project | async; as project) {
        @let status = project.status?.status || '';
        <div class="me-3 h-100 d-flex align-items-center">
          <phar-status-badge
            class="form-status-badge"
            [type]="EntityType.Project"
            [status]="status || Statuses.Draft" />

          @if (
            status === Statuses.Released ||
            status === Statuses.Rejected ||
            status === Statuses.Amended
          ) {
            <button
              class="ms-auto list-card_more"
              mat-icon-button
              [matMenuTriggerFor]="actionsMenu"
              (click)="$event.stopImmediatePropagation()">
              <mat-icon class="more">more_vert</mat-icon>
            </button>
            <mat-menu #actionsMenu="matMenu" [overlapTrigger]="false">
              @if (status === Statuses.Rejected || status === Statuses.Amended) {
                <button class="button-with-icon" mat-menu-item (click)="backToDraftAndUnlock(project)">
                  <mat-icon svgIcon="change" />
                  <span>Return to Draft</span>
                </button>
              }
              @if (status === Statuses.Released) {
                <button class="button-with-icon" mat-menu-item (click)="amendStudy(project)">
                  <mat-icon svgIcon="change" />
                  <span>Amend</span>
                </button>
              }
            </mat-menu>
          }
        </div>
      }
    </phar-second-level-menu>
  } @else {
    <phar-third-level-menu [bottomBordered]="true" />
  }
  <main class="context-mainContent scroll-holder" [class.scroll-holder--no-header]="!!!(header | async)">
    <router-outlet />
  </main>
</div>
