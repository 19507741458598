/* src/app/shared/file-upload/file-upload.component.scss */
#file-upload-container {
  border: 2px dashed var(--mdc-outlined-text-field-outline-color);
  border-radius: 5px;
  width: 350px;
  text-align: center;
  padding: 15px;
}
#file-upload-container #hint {
  font-size: 12px;
  color: #86878c;
}
#file-upload-container .preview-image {
  max-height: 96px;
  max-width: 240px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.fileover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
/*# sourceMappingURL=file-upload.component.css.map */
