<!-- <mat-toolbar mfmSecondHeader>
  <div class="mfm-second-header_left">
    <mfm-context-header></mfm-context-header>
  </div>
  <div class="mfm-second-header_center"></div>
  <div class="mfm-second-header_right"></div>
</mat-toolbar> -->

@if (navigateBackLinkInfo$ | async; as navigateBackLinkInfo) {
  <a class="phar-back-link" [routerLink]="navigateBackLinkInfo.routerLink">
    <mat-icon svgIcon="arrow-left" />
    {{ navigateBackLinkInfo.title }}
  </a>
}

<div class="d-flex flex-column">
  <phar-second-level-menu [links]="secondLevelLinks" />
  <main class="context-mainContent scroll-holder">
    <router-outlet />
  </main>
</div>
