<div class="approval" [ngClass]="step().done && step().successClass ? step().successClass : ''">
  <div class="approval_info" [ngClass]="{ approval_info_active: step().active, approval_info_approved: step().done }">
    <div class="approval_info_badge">
      @if (step().done) {
        <mat-icon [svgIcon]="step().icon || 'check'" />
      } @else {
        @if (index() !== undefined) {
          <span>{{ index() }}</span>
        } @else {
          <mat-icon [svgIcon]="step().icon || 'check'" />
        }
      }
    </div>
    <span class="approval_info_label">{{ step().label }}</span>
  </div>
  <div class="approval_body">
    <ng-content />
  </div>
</div>
