import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ListProjectsComponent } from './list-projects/list-projects.component';
import { SharedModule } from '../shared/shared.module';
import { ProjectService } from './project.service';
import { NewProjectComponent } from './new-project/new-project.component';
import { ProjectRoutingModule } from './project-routing.modue';
import { ProjectContextComponent } from './project-context/project-context.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { AssignModule } from '../assign/assign.module';
import { ExportDataModule } from '../export-data/export-data.module';
import { PharEventModule } from '../events/event.module';
import { StudyApprovalsComponent } from './study-approvals/study-approvals.component';
import { ListTemplateComponent } from '../shared/list-template/list-template.component';
import { UserByIdPipe } from '../form/pipes/user-by-id.pipe';
import { PharTooltipDirective } from '../shared/directives/tooltip.directive';
import { DndDirective } from '../shared/directives/dnd.directive';
import { FileUploadComponent } from '../shared/file-upload/file-upload.component';
import { IdBuilderComponent } from '../shared/id-builder/id-builder.component';
import { HelpIconComponent } from '../shared/help-icon/help-icon.component';
import { TranslateIdPipe } from '../shared/id-builder/translate-id-pipe';
import { TableListViewComponent } from '../shared/table-list-view/table-list-view.component';
import { StudyApprovalStepsComponent } from './study-approvals/study-approval-steps/study-approval-steps.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ProjectRoutingModule,
    AssignModule,
    ExportDataModule,
    PharEventModule,
    ListTemplateComponent,
    UserByIdPipe,
    PharTooltipDirective,
    DndDirective,
    FileUploadComponent,
    IdBuilderComponent,
    HelpIconComponent,
    TranslateIdPipe,
    TableListViewComponent,
    StudyApprovalStepsComponent,
    // MfmRecordModule,
    // DatasetViewModule,
  ],
  providers: [ProjectService],
  declarations: [
    ListProjectsComponent,
    NewProjectComponent,
    ProjectContextComponent,
    ProjectDetailsComponent,
    StudyApprovalsComponent,
    // ProjectDataComponent,
    // ProjectDataViewsComponent,
  ],
})
export class PharProjectModule {}
