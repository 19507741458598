/* src/app/assign/study-assessments/study-assessments.component.scss */
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move !important;
}
.full-width,
.three-dots-no-wrap {
  width: 100%;
}
.three-dots {
  overflow: hidden;
  text-overflow: ellipsis;
}
.three-dots-no-wrap {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.white-space-normal {
  white-space: normal;
}
.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.position-fixed {
  position: fixed;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.full-height {
  height: 100vh;
}
.full-height.fit {
  height: calc(100vh - 50px - 50px);
}
.max-height-100 {
  max-height: 100%;
}
.max-height-50 {
  max-height: 50%;
}
.max-width-100 {
  max-width: 100%;
}
.max-width-50 {
  max-width: 50%;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}
.max-width {
  max-width: 780px;
}
.background-transparent {
  background: transparent !important;
}
.underline-on-hover:hover {
  text-decoration: underline;
}
.text-underline {
  text-decoration: underline;
}
.no-pointer-events {
  pointer-events: none;
}
.hidden {
  display: none;
}
.h-auto {
  height: auto;
}
.h-100 {
  height: 100%;
}
.t-0 {
  top: 0;
}
.b-0 {
  bottom: 0;
}
.l-0 {
  left: 0;
}
.r-0 {
  right: 0;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}
.align-end {
  align-items: flex-end;
}
.flex-grow {
  flex-grow: 1;
}
.inline-block {
  display: inline-block;
}
.small-icon {
  width: 1rem !important;
  min-width: 1rem !important;
  height: 1rem !important;
}
button.reverse-icon {
  display: flex;
  flex-direction: row;
}
button.reverse-icon mat-icon {
  margin: 0 0 0 8px !important;
}
.text-strike-out {
  text-decoration: line-through;
}
.assessment-content {
  --leftBarWidth: 285px;
  --rightBarWidth: 250px;
  display: grid;
  grid-gap: 0;
  grid-template-columns: var(--leftBarWidth) 1fr var(--rightBarWidth);
  grid-template-rows: 100%;
  grid-template-areas: "leftbar body rightbar";
  height: calc(100vh - var(--main-header-height) - var(--secondary-header-height) - 30px);
}
.assessment-content_no-left-bar {
  --leftBarWidth: 0;
}
.assessment-content_no-right-bar {
  --rightBarWidth: 0;
}
.assessment-content_leftbar {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow:
    0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.assessment-content_leftbar_search-title {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding: 1.25rem 1.25rem 0 1.25rem;
}
.assessment-content_leftbar_search {
  display: flex;
  padding: 0 1.25rem;
  gap: 0.75rem;
}
.assessment-content_leftbar_search-field {
  font-size: 0.75rem;
  font-weight: 600;
}
.assessment-content_leftbar h6 {
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 0;
}
.assessment-content_leftbar h6 .mat-icon {
  position: relative;
  top: -4px;
}
.assessment-content_leftbar_forms-section {
  overflow-y: auto;
  flex: 1;
  padding: 0 1.25rem;
}
.assessment-content_rightbar {
  height: 100%;
  padding: 20px;
}
.assessment-content_body {
  padding: 1.25rem;
}
.form-template {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  height: 2rem;
  margin: 0.25rem 0;
  padding: 0 0.5rem;
  border-radius: 0.5rem;
}
.form-template_left-block {
  text-overflow: ellipsis;
  overflow: hidden;
}
.form-template_left-block_form-icon {
  height: 1.25rem !important;
  margin-right: 0.25rem;
  line-height: 1.25rem !important;
}
.form-template_right-block {
  margin-left: 0.25rem;
}
.form-template_right-block .eye-button {
  display: none;
}
.form-template:hover {
  background-color: #f6f6f7;
}
.form-template:hover .eye-button {
  display: inline-block;
}
.drop-placeholder {
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  border: 1px dashed #e3e7f2;
  background: #e2f0e7;
}
.drop-placeholder.children {
  background: rgba(66, 143, 94, 0.7) !important;
}
/*# sourceMappingURL=study-assessments.component.css.map */
