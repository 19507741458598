import { Component, inject, Inject, Injector, OnInit, Signal } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { FormModel } from '../../form/form.model';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { PharAutocompleterComponent } from '../../shared/phar-dropdown-list-autocompleter/phar-autocompleter.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import { Statuses } from '../../shared/models/statuses.enum';
import { ButtonComponent } from '../../shared/phar-button/phar-button.component';

export interface IReplaceAssessmentDialogData {
  forms: FormModel[];
}

@Component({
  selector: 'phar-replace-assessment-dialog',
  templateUrl: 'replace-assessment-dialog.component.html',
  styleUrls: ['replace-assessment-dialog.component.scss'],
  standalone: true,
  imports: [
    MatIcon,
    MatIconButton,
    MatDialogContent,
    MatButton,
    MatDialogActions,
    MatFormField,
    PharAutocompleterComponent,
    ReactiveFormsModule,
    MatDialogTitle,
    ButtonComponent,
  ],
})
export class ReplaceAssessmentDialogComponent implements OnInit {
  selectedFormControl = new FormControl<number>(null);
  selectedForm: Signal<FormModel | null>;
  injector = inject(Injector);

  constructor(
    public dialogRef: MatDialogRef<ReplaceAssessmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IReplaceAssessmentDialogData,
  ) {}

  ngOnInit(): void {
    this.selectedForm = toSignal(
      this.selectedFormControl.valueChanges.pipe(
        map(id => {
          if (!id) {
            return null;
          }

          return this.data.forms?.find(form => form.id === id) || null;
        }),
      ),
      { injector: this.injector },
    );
  }

  close(): void {
    this.dialogRef.close();
  }

  replace(): void {
    this.dialogRef.close(this.selectedForm());
  }

  protected readonly Statuses = Statuses;
}
