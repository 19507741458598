import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[pharScrollEndRecognizer]',
  standalone: true,
})
export class ScrollEndDirective {
  @Output() scrollEnd = new EventEmitter<void>();

  private scrollTimeoutId: number;

  @HostListener('scroll', [])
  public emitScrollEndEvent() {
    // On each new scroll event, clear the timeout.
    window.clearTimeout(this.scrollTimeoutId);

    // Only after scrolling has ended, the timeout executes and emits an event.
    this.scrollTimeoutId = window.setTimeout(() => {
      this.scrollEnd.emit();
      this.scrollTimeoutId = null;
    }, 100);
  }
}
