/* src/app/project/list-projects/list-projects.component.scss */
.project-sort {
  margin-right: 16px;
  background: #f0f0f2;
  border-radius: 5px;
  color: rgba(45, 76, 158, 0.6);
}
.project-sort_button {
  font-size: 14px;
}
#studies-list {
  height: calc(100vh - var(--main-header-height) - 112px) !important;
}
.user-info {
  max-width: 250px;
}
/*# sourceMappingURL=list-projects.component.css.map */
