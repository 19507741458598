import { Observable } from 'rxjs';
import { ProjectEventModel } from './project-event.model';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { EventModel } from './event.model';

export function uniqueEventNameValidator(
  listEvents: Observable<ProjectEventModel[]>,
  currentEvent: EventModel,
): AsyncValidatorFn {
  const errorMsg = { notUnique: true };

  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return listEvents.pipe(
      map((projectEvents: ProjectEventModel[]) => projectEvents.map(event => event.event)),
      map((events: EventModel[]) => {
        if (!events.length) {
          return null;
        }
        const rawValue = control.value.toLowerCase().trim();
        const controlsWithSameLabel = events.filter((event: EventModel) => {
          return event.eventName.toLowerCase().trim() === rawValue && event.id !== currentEvent.id;
        });

        return controlsWithSameLabel.length ? errorMsg : null;
      }),
      take(1),
    );
  };
}
