import { Component, inject, OnDestroy, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import { AppState } from '../../store/models/app.state';
import { slideInOut } from '../../animations';
import { addLoader, globalLoading, removeContextTitle } from '../../store/actions/ui.actions';
import {
  dispatchedCreateProject,
  pendingCreateProject,
  resetCurrentProject,
  searchProjectById,
  selectCurrentProjectManageProject,
  selectCurrentProjectManageProjectSnapshot,
  updateProject,
  updateProjectStatus,
} from '../store/project.actions';
import { ProjectModel } from '../project.model';
import { HeaderService } from '../../layout/phar-header/header.service';
import { NavigateBackLinkInfo, ProjectRouteData } from '../../core/models/route-data.interface';
import { EntityType } from '../../core/models/entity-type-enum';
import { PharConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog-service.service';
import { UtilsService } from '../../core/utils.service';
import { BaseComponent } from '../../shared/base.class';
import { ProjectService } from '../project.service';
import { Statuses } from '../../shared/models/statuses.enum';

@Component({
  templateUrl: './project-context.component.html',
  styleUrls: ['./project-context.component.scss'],
  animations: [slideInOut],
})
export class ProjectContextComponent extends BaseComponent implements OnInit, OnDestroy {
  currentRoute: string;
  header: Observable<boolean>;
  hideSecondLevelMenu: boolean;
  navigateBackLinkInfo$: Observable<NavigateBackLinkInfo>;
  project: Observable<ProjectModel>;
  store: Store<AppState> = inject(Store);
  projectSnapshot$: Observable<ProjectModel>;
  projectId: number;
  secondLevelLinks = [
    {
      text: 'Properties',
      url: 'details',
    },
    {
      text: 'Admin team',
      url: 'admins',
      isDisabled: true,
    },
    {
      text: 'Sites and Study Team',
      url: 'sites-study-team',
      isDisabled: true,
    },
  ];
  sidebar: Observable<string>;
  protected readonly EntityType = EntityType;
  private readonly routesPerPermissions = {
    read: [
      {
        text: 'Assessments',
        url: 'assessments',
      },
    ],
    edit: [
      {
        text: 'Events',
        url: 'events',
      },
      {
        text: 'Schedule of Assessments',
        url: 'schedule-assessments',
      },
    ],
    noPermissions: [
      { text: 'Approvals', url: 'approvals' },
      // { text: 'Export', url: 'export-study' },
    ],
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: PharConfirmDialogService,
    private utilsService: UtilsService,
    private projectService: ProjectService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(removeContextTitle({ position: 1 }));
    this.navigateBackLinkInfo$ = this.activatedRoute.data.pipe(
      map(({ navigateBackLink }: ProjectRouteData) => navigateBackLink),
    );

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: any) => {
      if (event.url) {
        const url: string = event.url;

        if (!this.currentRoute) {
          this.currentRoute = url;
        }
      }
    });

    this.loadSecondLevelLinks();

    this.store.dispatch(resetCurrentProject());
    this.projectId = parseInt(this.route.snapshot.paramMap.get('projectId'), 10);
    this.header = this.store.select(state => state.ui.header);
    this.sidebar = this.store.select(s => s.ui.sidebar);
    this.project = this.store.select(selectCurrentProjectManageProject);
    this.projectSnapshot$ = this.store.select(selectCurrentProjectManageProjectSnapshot);

    if (this.projectId) {
      this.store.dispatch(globalLoading(true));
      this.store.dispatch(searchProjectById({ id: this.projectId, addLoader: true }));

      this.projectSnapshot$
        .pipe(
          map(project => project.name),
          filter(projectName => !!projectName),
          takeUntil(this.destroy$),
        )
        .subscribe(projectName => {
          this.headerService.setHeaderText(projectName);
        });
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(resetCurrentProject());
  }

  loadSecondLevelLinks(): void {
    // if (this.permissionsService.getPermission('AssignmentRead')) {
    this.secondLevelLinks = [...this.secondLevelLinks, ...this.routesPerPermissions.read];
    // }
    // if (this.permissionsService.getPermission('AssignmentEdit')) {
    this.secondLevelLinks = [...this.secondLevelLinks, ...this.routesPerPermissions.edit];

    // }
    this.secondLevelLinks = [...this.secondLevelLinks, ...this.routesPerPermissions.noPermissions];
  }

  backToDraftAndUnlock(project: ProjectModel): void {
    // const defaultVersion = '0.1';
    // const proposedNewVersion = this.utilsService.incrementVersion(project.projectVersion || defaultVersion, 'minor');

    this.confirmationService
      .openConfirmDialog(
        `You are about to return this Study to <b>Draft</b>, Are you sure?` /*`Do you want to update the version number to ${proposedNewVersion}?`*/,
        `Please Confirm` /*`${project.name} V${project.projectVersion ?? defaultVersion}`*/,
        'Yes',
        'No',
      )
      .pipe(
        // switchMap(isConfirmed => {
        //   if (isConfirmed) {
        //     return of(proposedNewVersion);
        //   }
        //
        //   return this.dialog
        //     .open(VersionInputDialogComponent, {
        //       width: '300px',
        //       data: {
        //         title: `${project.name} V${project.projectVersion ?? defaultVersion}`,
        //         defaultVersion: project.projectVersion ?? defaultVersion,
        //       },
        //     })
        //     .afterClosed();
        // }),
        take(1),
        filter(x => !!x),
      )
      .subscribe(() => {
        this.updateProject(
          {
            ...project,
          },
          Statuses.Draft,
        );
      });
  }

  amendStudy(project: ProjectModel): void {
    const confirmMessage = 'Do you want to amend ' + project.name + '?';

    this.confirmationService
      .openConfirmDialog(confirmMessage)
      .pipe(
        take(1),
        filter(isConfirmed => !!isConfirmed),
        switchMap(() => {
          return this.projectService.amendProject(project.id);
        }),
        switchMap(project => {
          return this.confirmationService.openConfirmDialog('Do you want to continue editing this study?').pipe(
            take(1),
            filter(isConfirmed => isConfirmed),
            map(() => project),
          );
        }),
      )
      .subscribe(project => {
        this.router.navigate(['../', project.id], { relativeTo: this.activatedRoute });
      });
  }

  private updateProject(project: ProjectModel, status: Statuses) {
    this.utilsService.dispatchActions(this.store, [
      updateProjectStatus({ projectId: project.id, newStatus: status }),
      pendingCreateProject({ pending: true }),
      dispatchedCreateProject({ dispatched: true }),
      updateProject({ project }),
    ]);
  }

  protected readonly Statuses = Statuses;
}
