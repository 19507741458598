<aside class="widgets-container_content_rightbar group-editor-container px-2">
  <h3 class="mb-4">{{ assignment ? 'Update' : 'New' }} Group</h3>

  <form [formGroup]="form">
    <div class="d-flex flex-column">
      <mat-label class="required-field">Name</mat-label>
      <mat-form-field>
        <input
          formControlName="group"
          matInput
          pharInputTrim
          placeholder="Enter Group name"
          [maxlength]="50"
          [pharMaxLengthTooltip]="50" />
        <mat-error>
          @if (form.get('group').hasError('required')) {
            Group name is <b>required</b>
          } @else if (form.get('group').hasError('notUniqueName')) {
            Group name must be <b>unique</b>
          }
        </mat-error>
      </mat-form-field>
    </div>
  </form>

  <div class="mt-2 d-flex">
    <!--    <ng-template [ngxPermissionsOnly]="'ProjectEdit'">-->
    <button class="ms-auto btn-small me-3" mat-raised-button (click)="closeEditor()">
      <mat-icon [svgIcon]="'close'" />
      <b>Discard</b>
    </button>
    <!--    </ng-template>-->

    <!--    <ng-template [ngxPermissionsOnly]="'ProjectEdit'">-->
    <button class="ms-auto btn-small button-success" mat-raised-button (click)="saveGroup()">
      <mat-icon [svgIcon]="'check'" />
      <b>{{ assignment?.id ? 'Update' : 'Save' }}</b>
    </button>
    <!--    </ng-template>-->
  </div>
</aside>
