/* src/app/project/project-details/project-details.component.scss */
#form-info {
  height: calc(100% - 50px);
}
#project-actions {
  height: 50px;
  border-top: 1px solid var(--mat-menu-divider-color);
}
.form-field-text {
  font-size: 0.8rem;
}
#project-details,
#settings {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
::ng-deep #patient-hint {
  position: absolute;
  top: 0;
  font-size: small;
  right: 10px;
}
mat-form-field {
  display: block;
}
.amend-project-badge,
.original-project-badge {
  border-radius: 0.5rem;
  color: #272931;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  font-weight: 600;
  min-width: 65px;
  margin-left: 0.25rem;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.amend-project-badge:hover,
.original-project-badge:hover {
  background-color: #f6f6f7;
}
.amend-project-badge_icon,
.original-project-badge_icon {
  margin-right: 0.25rem;
}
.amend-project-badge_project-name,
.original-project-badge_project-name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.number-to-start mat-form-field {
  width: 95px;
}
.r-delimiter {
  border-right: 1px solid #e5e5e6;
}
#form-container {
  height: calc(100% - 50px);
}
#form-container #delimiter {
  background: #e5e5e6;
}
#form-actions {
  border-top: 1px solid #e5e5e6;
  height: 50px;
}
/*# sourceMappingURL=project-details.component.css.map */
