/* src/app/events/event-notifications/event-notification/event-notification.component.scss */
:host {
  display: block;
}
:host .event-notification {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d7d7d7;
}
:host .event-notification_pending-changes {
  border-color: #c74940;
}
:host .event-notification .header-icon {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
}
:host .event-notification .notification-title {
  display: block;
  font-size: 12px;
}
:host .event-notification .time-picker-field {
  display: block;
  margin-bottom: 22px;
}
:host .event-notification .time-picker-field ::ng-deep .form-control {
  height: 38px;
}
:host .event-notification .chip-grid-user-name {
  text-overflow: ellipsis;
  overflow: hidden;
}
:host .event-notification ::ng-deep .mat-mdc-chip,
:host .event-notification ::ng-deep span.mat-mdc-chip-action,
:host .event-notification ::ng-deep .mat-mdc-chip-action-label {
  overflow: hidden;
}
/*# sourceMappingURL=event-notification.component.css.map */
